<template>
  <DataTable
    :value="taskDetails"
    :paginator="true"
    class="p-datatable-customers p-datatable-striped"
    :rows="10"
    dataKey="id"
    :rowHover="true"
    :autoLayout="true"
    :loading="isLoading"
  >
    <template #empty>Список запчастей пуст</template>
    <template #loading>Загрузка...</template>
    <Column field="article" header="Артикул" :sortable="true">
      <template #body="slotProps">{{ slotProps.data.article }}</template>
    </Column>
    <Column field="name" header="Наименование" :sortable="true">
      <template #body="slotProps">{{ slotProps.data.name }}</template>
    </Column>
    <Column field="unit" header="Единица" :sortable="true">
      <template #body="slotProps">{{ slotProps.data.unit }}</template>
    </Column>
    <Column field="allCount" header="Запчастей получено" :sortable="true">
      <template #body="slotProps">{{ slotProps.data.allCount }}</template>
    </Column>
    <Column field="useCount" header="Запчастей использовано" :sortable="true">
      <template #body="slotProps">
        <div class="editMode">
          <div v-if="!editId" style="width: 100%">
            {{ slotProps.data.useCount }}
          </div>
          <InputNumber
            mode="decimal"
            :minFractionDigits="2"
            :maxFractionDigits="5"
            v-model="inputCurrentValue"
            v-if="editId === slotProps?.data?.id"
          />
          <Button
            class="p-button-warning"
            icon="pi pi-pencil"
            v-if="
              !editId &&
              (task?.engineer?.id === myId ||
                role === ROLE.OPERATOR ||
                role === ROLE.MAIN_OPERATOR ||
                role === ROLE.CHIEF_ENGINEER ||
                role === ROLE.HEAD_OF_SERVICE)
            "
            @click="
              turnOnEditMode(slotProps?.data?.id, slotProps.data.useCount)
            "
          />
          <Button
            icon="pi pi-check"
            v-if="editId === slotProps?.data?.id"
            @click="changeUseCount(slotProps.data)"
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <div class="p-mt-4">
    <div class="p-mb-1">Дополнительные запчасти</div>
    <Textarea
      :class="!textDetail.isValid ? 'p-invalid' : ''"
      v-model="textDetail.val"
      rows="3"
      :autoResize="true"
      cols="100"
    />
    <Button @click="updateTextDetail" label="Сохранить" />
  </div>

  <div
    v-if="
      permissions['see_purchasing_parts_buttons'] &&
      task.status === 'PURCHASING_PARTS'
    "
    class="p-mt-4"
  >
    <span class="p-ml-2">Закупка запчастей</span>

    <div class="p-xl-4 p-lg-12 p-md-12 p-sm-12 p-xs-12">
      <Button
        v-for="button in responsibleButtons"
        :key="button.role"
        :label="button.label"
        class="p-mb-1"
        :class="selectCurrentResponsibleButtonClass(button.role)"
        @click="selectNewResponsibleRole(button.role)"
        :disabled="isLoading"
      />
    </div>
  </div>
  <TextareaDialog
    :is-visible-prop="showChangeResponsibleDialog"
    main-text="Вы уверены, что хотите сменить ответственного за закупку?"
    placeholder-text="Комментарий"
    @closeDialog="closeChangeResponsibleDialog"
    @update="closeChangeResponsibleDialogAndUpdate($event)"
  />
</template>

<script>
import { ROLE } from "@/models/roles";
import { environment } from "@/config";
import TextareaDialog from "@/views/operator/components/TextareaDialog";

export default {
  name: "TaskDetails",
  components: { TextareaDialog },
  props: ["task"],
  emits: ["detailsUseCount"],
  data() {
    return {
      ROLE,
      selectedResponsibleRole: null,
      showChangeResponsibleDialog: false,
      taskDetails: null,
      isLoading: false,
      textDetail: {
        val: null,
        isValid: true,
      },
      editId: null,
      inputCurrentValue: null,
      responsibleButtons: [
        {
          label: "Передать шеф-инженеру",
          role: ROLE.CHIEF_ENGINEER,
        },
        {
          label: "Передать инженеру технической поддержки",
          role: ROLE.TECHNICAL_SUPPORT_ENGINEER,
        },
        {
          label: "Передать менеджеру по закупке запасных частей",
          role: ROLE.PURCHASING_MANAGER,
        },
        {
          label: "Передать инженеру по гарантии",
          role: ROLE.WARRANTY_ENGINEER,
        },
        {
          label: "Передать оператору",
          role: ROLE.OPERATOR,
        },
      ],
    };
  },
  created() {
    this.textDetail.val = this.task.textDetail;
    if (this.task?.details) {
      this.loadDetails(this.task.details);
    }
  },
  computed: {
    permissions() {
      return this.$store.getters.getPermissions;
    },
    myId() {
      return this.$store.getters.getId;
    },
    role() {
      return this.$store.getters.getRole;
    },
  },
  methods: {
    selectNewResponsibleRole(role) {
      this.showChangeResponsibleDialog = true;
      this.selectedResponsibleRole = role;
    },
    closeChangeResponsibleDialog() {
      this.showChangeResponsibleDialog = false;
    },
    async closeChangeResponsibleDialogAndUpdate(reason) {
      this.isLoading = true;

      try {
        await fetch(
          `${environment.apiUrl}/v2/tasks/${this.task.id}/assign_purchasing_responsible`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
              role: this.selectedResponsibleRole,
              text: reason,
            }),
          }
        );
        //TODO Это антипаттерн, но тут это неплохая практика, подправить при рефакторинге
        // eslint-disable-next-line vue/no-mutating-props
        this.task.purchasingResponsible = this.selectedResponsibleRole;
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
      this.showChangeResponsibleDialog = false;
    },
    selectCurrentResponsibleButtonClass(role) {
      return this.task.purchasingResponsible === role
        ? null
        : "not-responsible";
    },
    async updateTextDetail() {
      this.isLoading = true;
      this.textDetail.isValid = true;
      if (this.textDetail.val.length > 300) {
        this.textDetail.isValid = false;
        this.isLoading = false;
        this.$toast.add({
          severity: "error",
          summary: "Ввести можно не более 300 символов",
          life: 6000,
        });
        return;
      }

      try {
        const tmp_task = this.task;
        tmp_task.textDetail = this.textDetail.val;
        await fetch(`${environment.apiUrl}/tasks/${this.task?.id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
          method: "PUT",
          body: JSON.stringify(tmp_task),
        });

        this.$toast.add({
          severity: "success",
          summary: "Сохранено",
          detail: "Дополнительные запчасти успешно сохранены",
          life: 6000,
        });
      } catch (err) {
        this.$toast.add({
          severity: "error",
          summary: "Поле не удалось сохранить",
          life: 600,
        });
        console.log(err);
      }
      this.isLoading = false;
    },
    async changeUseCount(detail) {
      this.isLoading = true;

      try {
        if (detail.allCount < this.inputCurrentValue) {
          this.$toast.add({
            severity: "error",
            summary: "Не удалось изменить количество запчастей",
            detail:
              "Число полученных запчастей не может быть меньше числа использованных",
            life: 6000,
          });
          this.isLoading = false;
          return;
        }
        if (this.inputCurrentValue < 0) {
          this.$toast.add({
            severity: "error",
            summary: "Не удалось изменить количество запчастей",
            detail: "Число запчастей не может быть меньше нуля",
            life: 6000,
          });
          this.isLoading = false;
          return;
        }

        detail.useCount = this.inputCurrentValue;

        const response = await fetch(
          `${environment.apiUrl}/details/${detail.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            method: "PATCH",
            body: JSON.stringify(detail),
          }
        );

        this.loadDetails(await response.json());

        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Количество запчастей изменено`,
          life: 6000,
        });
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
      this.editId = null;
    },
    turnOnEditMode(editId, currValue) {
      this.editId = editId;
      this.inputCurrentValue = currValue;
    },
    loadDetails(detailsArray) {
      this.taskDetails = detailsArray;

      let amount = 0;
      for (const detail of this.taskDetails) {
        if (detail.useCount === 0) {
          amount += 1;
        }
      }
      this.$emit("detailsUseCount", amount);
    },
  },
};
</script>

<style scoped>
.editMode {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.not-responsible {
  opacity: 0.4;
}
</style>
