<template>
  <div class="p-field">
    <div>{{ item.name }}</div>
    <FileUpload
      :disabled="isLoading"
      name="demo[]"
      class="p-mt-1 p-mb-1"
      chooseLabel="Выберите файлы"
      cancelLabel="Отмена"
      uploadLabel="Сохранить"
      ref="uploadBtn"
      :customUpload="true"
      :multiple="true"
      :showUploadButton="true"
      :showCancelButton="false"
      @uploader="myUploader"
      mode="advanced"
      @select="test"
      :id="`uploader_${id}`"
      :hidden="
        !(permissions['change_checklist'] && task.status === 'WORK_ENGINEER') ||
        task.engineer.id !== myId
      "
    >
      <template #empty>
        <p>Загрузите файл</p>
      </template>
    </FileUpload>
    <div
      v-for="file in taskFiles"
      :key="file.id"
      style="width: 300px; position: relative"
      class="p-mt-2 p-mb-6"
    >
      <img :src="file.obj" width="300" />
      {{ file.name }}

      <div class="delete-button-wrapper" v-if="permissions['change_checklist']">
        <Button
          @click="deleteImageFromChecklist(file.id)"
          icon="pi pi-times"
          class="p-button-sm p-button-danger delete-button"
          v-if="task.engineer.id === myId"
        />
      </div>
    </div>
    <div v-if="taskFiles.length === 0">Файлы ещё не приложены</div>
  </div>
</template>

<script>
import { environment } from "@/config";
import FileIcon from "@/views/tasks/FileIcon";
import Compressor from "compressorjs";
export default {
  components: { FileIcon },
  props: ["item", "task", "id"],
  name: "ChecklistFiles",
  data() {
    return {
      formFiles: null,
      taskFiles: [],
      isLoading: false,
      imageTypes: ["image/png", "image/jpeg", "image/jpg"],
    };
  },
  updated() {},
  created() {
    this.getFiles();
  },
  computed: {
    myId() {
      return this.$store.getters.getId;
    },
    permissions() {
      return this.$store.getters.getPermissions;
    },
    role() {
      return this.$store.getters.getRole;
    },
    uploadUrl() {
      return `${environment.apiUrl}/tasks/${this.task.id}/files`;
    },
    url() {
      return `${environment.apiUrl}/checklist_items/load`;
    },
  },
  watch: {
    item() {
      this.getFiles();
    },
  },
  methods: {
    test() {
      setTimeout(() => {
        let uploader = document.getElementById(`uploader_${this.id}`);
        let bar = uploader.getElementsByClassName("p-fileupload-buttonbar")[0];
        let button = bar.getElementsByTagName("button")[0];
        button.disabled = false;
        button.className = "p-button p-component";
        console.log(button.className);
      }, 300);
    },
    async deleteImageFromChecklist(id) {
      let tmp_file = this.item;
      tmp_file.files = tmp_file.files.filter((f) => {
        return f.id !== id;
      });
      const response = await fetch(this.url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify([tmp_file]),
      });

      this.taskFiles = this.taskFiles.filter((t) => {
        return t.id !== id;
      });
    },
    async getFiles() {
      if (!this?.item?.files) return;
      this.taskFiles = [];
      for (const fileItem of this?.item?.files) {
        await fetch(
          `${environment.apiUrl}` +
            "/files/" +
            fileItem.file.path.replace(/\//g, "%2F"),
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        )
          .then((response) => {
            return response.blob();
          })
          .then((response) => {
            this.taskFiles.push({
              id: fileItem.id,
              obj: URL.createObjectURL(response),
              name: fileItem?.file?.name,
            });
          });
      }
    },
    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          maxWidth: 1920,
          maxHeight: 1920,
          success: resolve,
          error: reject,
        });
      });
    },
    async myUploader(event) {
      console.log(event.files);
      this.formFiles = event.files;
      this.isLoading = true;

      try {
        let tmp_item = this.item;

        if (!tmp_item?.files) {
          tmp_item.files = [];
        }
        for (const f of this.formFiles) {
          let file = f;
          if (this.imageTypes.includes(file.type)) {
            file = await this.compressImage(file);
            if (file instanceof Blob) {
              file = new File([file], file.name);
            }
          }
          console.log(file);

          const data = new FormData();
          data.append("file", file);

          const response = await fetch(this.uploadUrl, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
            body: data,
          });
          const result = await response.json();
          tmp_item.files.push({
            checklistItemInfoId: this.item.id,
            file: result,
          });
        }

        const response = await fetch(this.url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify([tmp_item]),
        });

        this.$refs.uploadBtn.clear();
        this.$emit("updatedText");
        // await this.getFiles();
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.delete-button-wrapper {
  position: absolute;
  right: 0;
  top: 0;
}
.delete-button {
  width: 1.7rem;
  height: 1.7rem;
  padding: 0;
}
</style>
