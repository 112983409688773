<template>
  <Dialog
      :header="header"
      v-model:visible="isVisible"
      :style="{ width: '350px' }"
      :modal="true"
      :closable="false"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
      <span>{{ message }}</span>
    </div>
    <template #footer>
      <Button
          label="Нет"
          icon="pi pi-times"
          @click="onCancel"
          class="p-button-text"
      />
      <Button
          label="Да"
          icon="pi pi-check"
          @click="onConfirm"
          class="p-button-text"
          autofocus
      />
    </template>
  </Dialog>
</template>

<script>

export default {
  name: "ConfirmationDialog",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    header: {
      type: String,
      default: 'Внимание!',
    },
    message: {
      type: String,
      required: true,
    },
    onConfirm: {
      type: Function,
      required: true,
    },
    onCancel: {
      type: Function,
      required: true,
    },
  },
  computed: {
    isVisible() {
      return this.visible
    }
  }
};
</script>

<style scoped>
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>