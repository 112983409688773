<template>
  <div v-if="logbook.length > 0">
    <div
      class="p-col-12"
      v-for="event in logbook"
      :key="event.id"
      style="white-space: pre-line"
    >
      <div class="p-d-flex">
        <div class="p-text-bold p-mr-3 p-mb-3">
          {{ event?.user?.firstname }} {{ event?.user?.lastname }}
        </div>

        <div>
          {{ event?.name }}
        </div>
      </div>

      <div v-if="event?.beforeText">
        <div class="p-mb-3 p-text-bold" style="color: #6c757d">До</div>
        <div class="p-mb-3">
          {{ event?.beforeText }}
        </div>
      </div>

      <div v-if="event?.afterText">
        <div class="p-mb-3 p-text-bold" style="color: #6c757d">После</div>
        <div class="p-mb-1">
          {{ event?.afterText }}
        </div>
      </div>

      <div class="p-flex-row p-text-right">
        {{ getFormattedDate(event?.created) }}
      </div>
      <hr />
    </div>
  </div>
  <div v-else>Записи в журнале отсутствуют</div>
</template>

<script>
import { environment } from "../../config";
import dayjs from "dayjs";

export default {
  props: ["taskId"],
  name: "TaskLogbook",
  data() {
    return {
      isLoading: false,
      logbook: [],
    };
  },
  async mounted() {
    await this.loadLogbook();
  },
  methods: {
    getFormattedDate(date) {
      return dayjs(new Date(date)).format("DD.MM.YY HH:mm");
    },
    async loadLogbook() {
      if (!this.taskId) return;
      this.isLoading = true;
      try {
        const response = await fetch(
          `${environment.apiUrl}/tasks/${this.taskId}/logbook`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.logbook = await response.json();
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped></style>
