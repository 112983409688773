<template>
  <div class="p-field-checkbox">
    <Checkbox
      id="check"
      name="check"
      v-model="isChecked"
      :binary="true"
      @change="clickedCheckbox"
      :disabled="
        !(permissions['change_checklist'] && task.status === 'WORK_ENGINEER') ||
        task.engineer.id != myId
      "
    />
    <label for="check">{{ item.name }}</label>
  </div>
</template>

<script>
import { environment } from "@/config";
import StatusColor from "@/views/operator/components/StatusColor";

export default {
  name: "ChecklistCheckbox",
  components: { StatusColor },
  props: ["item", "task"],
  emits: ["updatedText"],
  data() {
    return {
      isLoading: false,
      isChecked: false,
    };
  },
  created() {
    if (this.item?.id && this.item.switchValue === true) {
      this.isChecked = true;
    }
  },
  computed: {
    myId() {
      return this.$store.getters.getId;
    },
    permissions() {
      return this.$store.getters.getPermissions;
    },
    role() {
      return this.$store.getters.getRole;
    },
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;
    },
    async clickedCheckbox() {
      if (!this?.item?.id) return;

      this.validateForm();

      if (!this.formIsValid) {
        return;
      }

      this.isLoading = true;

      try {
        const tmp_item = this.item;
        tmp_item.switchValue = this.isChecked;
        console.log(JSON.stringify(tmp_item));
        const response = await fetch(
          `${environment.apiUrl}/checklist_items/load`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            body: [JSON.stringify(tmp_item)],
          }
        );

        // const responseData = await response.json();

        if (response.status !== 200) {
          throw new Error("Ошибка при сохранении галочки!");
        }
        this.$emit("updatedText");

        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Выполнено!`,
          life: 6000,
        });
      } catch (err) {
        const error = err.message || "Не получилось сохранить галочку";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при сохранении галочки!",
          detail: error,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
.p-field-checkbox {
  margin-bottom: 0;
}
</style>
