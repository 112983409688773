<template>
  <div>
    <div style="width: 300px; position: relative">
      <img
        v-if="type === 'image'"
        :src="file.obj"
        width="300"
        :alt="file.path"
        @click="saveFile(file)"
      />
      <div class="delete-button">
        <!--        TODO Раскомменить кнопку если она нужна будет-->
<!--        <Button-->
<!--          @click="deleteImageFromChecklist()"-->
<!--          icon="pi pi-times"-->
<!--          class="p-button-sm p-button-rounded p-button-danger"-->
<!--          v-if="myId === taskEngineerId && permissions['change_checklist']"-->
<!--        />-->
        <Button
          @click="toggleDialogVisibility"
          icon="pi pi-times"
          class="p-button-sm p-button-danger"
          v-if="permissions['delete_files_from_files']"
        />
      </div>
    </div>
    <img
      v-if="type === 'docx'"
      src="/assets/docx.png"
      width="100"
      :alt="file.path"
      @click="saveFile(file)"
    />
    <img
      v-if="type === 'csv'"
      src="/assets/csv.svg"
      width="100"
      :alt="file.path"
      @click="saveFile(file)"
    />
    <img
      v-if="type === 'pdf'"
      src="/assets/pdf.svg"
      width="100"
      :alt="file.path"
      @click="saveFile(file)"
    />
    <Button
      v-if="type === 'unknown'"
      style="width: 200px"
      @click="saveFile(file)"
      class="p-col-3"
      >{{ file?.name }}
    </Button>
  </div>
  <div class="p-mb-4" v-if="type !== 'image'">
    {{ file?.name }}
  </div>
  <div>
    <ConfirmationDialog
        :visible="isDeletionConfirmationVisible"
        :on-confirm="deleteFileFromFiles"
        :message="'Вы уверены, что хотите удалить файл?'"
        :on-cancel="toggleDialogVisibility"
    />
  </div>
</template>

<script>
import { environment } from "@/config";
import ConfirmationDialog from "@/views/operator/components/ConfirmationDialog.vue";

export default {
  name: "FileIcon",
  components: {ConfirmationDialog},
  props: ["file", "role", "itemId", "taskEngineerId", "hasDeleteButton"],
  emits: ["deleteFileFromChecklistArray", "loadFiles"],
  data() {
    return {
      type: "unknown",
      isDeletionConfirmationVisible: false,
    };
  },
  created() {
    const extension = this.file.path.split(".").pop();
    if (["png", "jpg", "jpeg", "webp"].indexOf(extension) > -1) {
      this.type = "image";
    }
    if (extension === "csv") {
      this.type = "csv";
    }
    if (extension === "pdf") {
      this.type = "pdf";
    }
    if (extension === "docx") {
      this.type = "docx";
    }
  },
  computed: {
    myId() {
      return this.$store.getters.getId;
    },
    permissions() {
      return this.$store.getters.getPermissions;
    },
    apiUrl() {
      return `${environment.apiUrl}`;
    },
  },
  methods: {
    toggleDialogVisibility() {
      this.isDeletionConfirmationVisible = !this.isDeletionConfirmationVisible
    },
    async deleteFileFromFiles() {
      this.toggleDialogVisibility()
      try {
        await fetch(`${environment.apiUrl}/files/${this.file.id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
        });
        this.$toast.add({
          severity: "success",
          summary: "Файл удален",
          detail: "Файл был успешно удален",
          life: 6000,
        });
        this.$emit("loadFiles");
      } catch (err) {
        console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при удалении файла!",
          detail: err,
          life: 6000,
        });
      }
    },
    saveFile(file) {
      const fSaver = require("file-saver");
      fSaver.saveAs(file.obj, file.name);
    },
    async deleteImageFromChecklist() {
      try {
        const response = await fetch(
          `${environment.apiUrl}/checklist_items/${this.itemId}/deleteFile`,
          {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status !== 200) {
          throw new Error("Ошибка при удалении изображения!");
        }

        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Выполнено!`,
          life: 6000,
        });
        this.$store.dispatch("data/deleteFileFromState", this.file);
        this.$emit("deleteFileFromChecklistArray");
      } catch (err) {
        const error = err.message || "Не удалось удалить изображение";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при удалении изображения!",
          detail: error,
          life: 6000,
        });
      }
    },
  },
};
</script>

<style scoped>
img {
  cursor: pointer;
}
.delete-button {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
