<template>
  <div>
    <TaskContract
      v-for="contract in contracts"
      :key="contract.name"
      :contract="contract"
    />
  </div>
</template>

<script>
import TaskContract from "@/views/tasks/TaskContract.vue";

export default {
  name: "TaskContractsList",
  components: { TaskContract },
  props: ["contracts"],
};
</script>
