<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="p-grid" v-for="status of history" :key="status?.id">
        <div class="p-col-3">{{ status?.createdText }}</div>
        <div class="p-col-4">{{ status?.statusText }}</div>
        <div class="p-col-5">
          {{ status?.roleText }}
          {{ status?.user?.lastname }}
          {{ status?.user?.firstname }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRoleText, getTaskStatusText } from "@/models/roles";
import dayjs from "dayjs";

export default {
  name: "TaskHistory",
  props: ["task"],
  created() {
    this.loadStatuses();
  },
  computed: {},
  data() {
    return {
      history: [],
    };
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;

      this.message.isValid = true;

      if (!this.message.val) {
        this.message.isValid = false;
        this.formIsValid = false;
      }
    },
    async loadStatuses() {
      if (!this.task?.id) return;
      this.history = this.task.histories;
      this.history = this.history.map((c) => {
        const d = new Date(c.created);
        return {
          ...c,
          createdText: dayjs(d).format("HH:mm DD.MM.YY"),
          roleText: getRoleText(c.user.role),
          statusText: getTaskStatusText(c.status),
        };
      });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep(.p-scrollpanel) {
  p {
    padding: 0.5rem;
    line-height: 1.5;
    margin: 0;
  }

  &.custombar1 {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--surface-b);
    }

    .p-scrollpanel-bar {
      background-color: var(--primary-color);
      opacity: 1;
      transition: background-color 0.2s;

      &:hover {
        background-color: #007ad9;
      }
    }
  }
}
</style>
