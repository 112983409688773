<template>
  <div class="p-field">
    <div>{{ item.name }}</div>
    <FileUpload
      mode="basic"
      name="demo[]"
      class="p-mt-1 p-mb-1"
      :maxFileSize="5000000"
      chooseLabel="Выберите файл"
      :auto="true"
      :customUpload="true"
      ref="uploadBtn"
      @uploader="myUploader"
      :hidden="
        !(permissions['change_checklist'] && task.status === 'WORK_ENGINEER') ||
        isLoading ||
        !!item?.file ||
        task.engineer.id !== myId
      "
    >
      <template #empty>
        <p>Загрузите файл</p>
      </template>
    </FileUpload>
    <div v-if="!!item?.file">
      Файл приложен
      <file-icon
        @deleteFileFromChecklistArray="deleteFile"
        v-if="fileLoaded"
        :file="taskFile"
        :itemId="item?.id"
        :role="role"
        :task-engineer-id="task.engineer.id"
        :has-delete-button="true"
      ></file-icon>
    </div>
    <div v-else>Файлы еще не приложены</div>
  </div>
</template>

<script>
import { environment } from "@/config";
import FileIcon from "@/views/tasks/FileIcon";

export default {
  name: "ChecklistFile",
  components: { FileIcon },
  props: ["item", "task"],
  emits: ["updatedText"],
  data() {
    return {
      files: [],
      filesObjects: [],
      fileToSend: [],
      isLoading: false,
      taskFile: null,
      fileLoaded: false,
    };
  },

  async updated() {
    this.getFiles();
  },
  mounted() {
    this.getFiles();
  },
  computed: {
    myId() {
      return this.$store.getters.getId;
    },
    permissions() {
      return this.$store.getters.getPermissions;
    },
    taskFiles() {
      return this.$store.getters["data/taskFiles"];
    },
    role() {
      return this.$store.getters.getRole;
    },
    url() {
      return `${environment.apiUrl}/checklist_items/load`;
    },
    apiUrl() {
      return `${environment.apiUrl}`;
    },
    uploadUrl() {
      return `${environment.apiUrl}/tasks/${this.task.id}/files`;
    },
  },
  watch: {
    taskFiles: function (filter) {
      this.getFiles();
    },
  },
  methods: {
    getFiles() {
      const file = this.$store.getters["data/taskFiles"].filter((f) => {
        return f.path === this.item?.file?.path;
      });
      this.taskFile = file.length === 0 ? null : file[0];
      if (this.taskFile) {
        this.fileLoaded = true;
      }
    },
    deleteFile() {
      this.$emit("updatedText");
    },
    async myUploader(event) {
      this.isLoading = true;
      const data = new FormData();
      data.append("file", event.files[0]);

      try {
        let response = await fetch(this.uploadUrl, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            // "Content-Type": "application/json",
          },
          body: data,
        });

        const result = await response.json();
        let tmp_item = this.item;
        tmp_item.file = result;
        response = await fetch(this.url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify([tmp_item]),
        });

        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Файл сохранён!`,
          life: 6000,
        });
        this.$refs.uploadBtn.clear();
        let newFile = result;
        newFile.href =
          this.apiUrl + "/files/" + newFile.path.replace(/\//g, "%2F");

        let fileObject = null;
        await fetch(newFile.href, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
          .then((response) => {
            return response.blob();
          })
          .then((response) => {
            fileObject = response;
          });

        fileObject = URL.createObjectURL(fileObject);
        newFile.obj = fileObject;
        this.$store.dispatch("data/pushNewTaskFile", newFile);
        this.$emit("updatedText");
      } catch (err) {
        const error = err.message || "Не получилось прикрепить файл";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при сохранении файла!",
          detail: error,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>
