<template>
  <div class="p-field">
    <label for="text">{{ item.name }}</label>
    <Textarea
      id="text"
      v-model.trim="text.val"
      :required="true"
      @blur="clearValidity('text')"
      :class="!text.isValid ? 'p-invalid' : ''"
      rows="4"
      :disabled="
        !(permissions['change_checklist'] && task.status === 'WORK_ENGINEER') ||
        task.engineer.id !== myId
      "
    />
    <Button
      v-if="
        !isLoading &&
        permissions['change_checklist'] &&
        task.status === 'WORK_ENGINEER' &&
        task.engineer.id === myId
      "
      label="Сохранить текст"
      @click="submitForm"
    ></Button>
  </div>
</template>

<script>
import { environment } from "@/config";

export default {
  name: "ChecklistText",
  props: ["item", "task"],
  emits: ["updatedText"],
  data() {
    return {
      isLoading: false,
      text: {
        val: "",
        isValid: true,
      },
    };
  },
  created() {
    if (this.item?.id) {
      this.text.val = this.item.text;
    }
  },
  computed: {
    myId() {
      return this.$store.getters.getId;
    },
    permissions() {
      return this.$store.getters.getPermissions;
    },
    role() {
      return this.$store.getters.getRole;
    },
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;
      this.text.isValid = true;
      if (!this.text.val) {
        this.text.isValid = false;
        this.formIsValid = false;
      }
    },
    async submitForm() {
      if (!this?.item?.id) return;

      this.validateForm();

      if (!this.formIsValid) {
        return;
      }

      this.isLoading = true;

      try {
        const tmp_item = this.item;
        tmp_item.text = this.text.val;
        console.log(tmp_item);
        const response = await fetch(
          `${environment.apiUrl}/checklist_items/load`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
            body: [JSON.stringify(tmp_item)],
          }
        );

        // const responseData = await response.json();

        if (response.status !== 200) {
          throw new Error("Ошибка при сохранении текста!");
        }
        this.$emit("updatedText");

        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Текст сохранён!`,
          life: 6000,
        });
      } catch (err) {
        const error = err.message || "Не получилось сохранить текст";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при сохранении текста!",
          detail: error,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>
