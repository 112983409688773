<template>
  <Dialog
    header="Внимание!"
    v-model:visible="isVisible"
    :style="{ width: '350px' }"
    :modal="true"
    :closable="false"
  >
    <div class="p-d-flex p-align-center">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span>{{ mainText }}</span>
    </div>
    <div style="width: 100%" class="p-mt-1 p-fluid">
      <Textarea
        v-model.trim="text"
        :required="true"
        style="width: 100%"
        :placeholder="placeholderText"
        :class="text.length === 0 ? 'p-invalid' : ''"
        rows="4"
      />
    </div>
    <template #footer>
      <Button
        label="Нет"
        icon="pi pi-times"
        @click="closeDialog"
        class="p-button-text"
      />
      <Button
        label="Да"
        icon="pi pi-check"
        @click="closeDialogAndUpdate"
        :disabled="text.length === 0"
        class="p-button-text"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "TextareaDialog",
  props: {
    isVisibleProp: {
      required: true,
      type: Boolean,
    },
    mainText: {
      required: true,
      type: String,
    },
    placeholderText: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      text: "",
    };
  },
  computed: {
    isVisible() {
      return this.isVisibleProp;
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    closeDialogAndUpdate() {
      this.$emit("update", this.text);
      this.text = "";
    },
  },
};
</script>

<style scoped></style>
