<template>
  <div class="p-grid">
    <ScrollPanel
      style="width: 100%; height: 500px"
      class="custombar1 p-mt-2"
      ref="spanel"
    >
      <div
        class="p-grid p-mb-3"
        v-for="(message, index) in comments?.messages"
        :key="message?.id"
      >
        <div
          class="p-col-12 p-d-flex p-jc-center p-mb-2"
          v-if="message.service === true && message.text !== 'Чат создан'"
        >
          <div class="p-col-6 p-text-center chat-created p-d-flex p-jc-center">
            <div class="chat-created">
              {{ message.createdText }}
              {{
                message.author !== undefined
                  ? `Автор: ${message.author.lastname} ${message.author.firstname}.`
                  : ""
              }}
              {{ message.text }}
            </div>
          </div>
        </div>
        <div class="p-col-12" v-if="message.service === false">
          <div
            class="p-col-12"
            v-if="
              message?.author?.id !==
                comments?.messages[index - 1]?.author?.id &&
              message?.author?.id !== userData.id
            "
          >
            <span class="username"
              >{{ message?.author?.lastname }}
              {{ message?.author?.firstname }}</span
            >
          </div>
          <div
            class="p-col-8 p-mb-1"
            :class="[{ hidden: message.hidden, public: !message.hidden }]"
            v-if="
              message?.author?.id !== userData.id &&
              !(role === 'EMPLOYEE_OF_CLIENT' && message.hidden === true)
            "
          >
            <span>
              {{ message.text }}
              <file-icon v-if="message.file" :file="message.file" />
              <div class="time p-text-right">
                <small
                  >{{ message?.createdText }}
                  <span
                    v-if="message?.createdText !== message?.postedText"
                    class="offtime"
                    >[{{ message?.postedText }}]</span
                  >
                  <i v-if="message.hidden" class="pi pi-eye-slash"></i>
                  <i v-else class="pi pi-eye"></i>
                </small>
              </div>
            </span>
          </div>
          <div
            v-if="
              message?.author?.id === userData.id &&
              !(role === 'EMPLOYEE_OF_CLIENT' && message.hidden === true)
            "
            class="p-grid p-pr-1 p-justify-end"
          >
            <div
              class="p-col-8 p-mr-2 p-mb-1"
              :class="[{ hidden: message.hidden, public: !message.hidden }]"
            >
              <span>
                {{ message.text }}
                <file-icon v-if="message.file" :file="message.file" />
                <div class="time p-text-right">
                  <small
                    >{{ message?.createdText }}
                    <span
                      v-if="message?.createdText !== message?.postedText"
                      class="offtime"
                      >[{{ message?.postedText }}]</span
                    >
                    <i v-if="message.hidden" class="pi pi-eye-slash"></i>
                    <i v-else class="pi pi-eye"></i
                  ></small>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </ScrollPanel>
    <InputText
      class="p-mt-2"
      id="message"
      placeholder="Сообщение"
      v-model.trim="message.val"
      :required="true"
      @blur="clearValidity('message')"
      @focus="readAllMessages"
      :class="!message.isValid ? 'p-invalid' : ''"
      v-on:keyup.enter="openConfirmation"
      :disabled="closed"
      maxlength="500"
    />
    <div
      class="p-field-checkbox p-mt-2 p-col-4"
      v-if="role !== 'EMPLOYEE_OF_CLIENT'"
    >
      <Checkbox
        id="private"
        name="private"
        value="private"
        v-model="isPrivateMessage"
      />
      <label for="private">Приватное</label>
    </div>
    <div class="p-mt-2">
      <FileUpload
        mode="basic"
        name="demo[]"
        class="p-mt-1 p-mb-1"
        :url="uploadUrl"
        chooseLabel="Выберите файл"
        :auto="true"
        :customUpload="true"
        ref="uploadBtn"
        @uploader="myUploader"
        :disabled="isLoading"
      >
        <template #empty>
          <p>Загрузите файл</p>
        </template>
      </FileUpload>
    </div>
  </div>
  <Dialog
    header="Внимание!"
    v-model:visible="displayConfirmation"
    :style="{ width: '350px' }"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span>Вы уверены, что отправить публичное сообщение?</span>
    </div>
    <template #footer>
      <Button
        label="Нет"
        icon="pi pi-times"
        @click="closeConfirmation"
        class="p-button-text"
      />
      <Button
        label="Да"
        icon="pi pi-check"
        @click="closeConfirmationAndSend"
        class="p-button-text"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { environment } from "@/config";
import dayjs from "dayjs";
import FileIcon from "@/views/tasks/FileIcon";
import Compressor from "compressorjs";
import { ROLE } from "@/models/roles";

export default {
  name: "TaskComments",
  components: { FileIcon },
  props: ["task", "commentsFromParent"],
  emits: ["newUnreadMessage", "readAllMessages"],
  async created() {
    await this.displayComments(this.commentsFromParent);
    if (
        [
          ROLE.ADMIN,
          ROLE.OPERATOR,
          ROLE.ENGINEER,
          ROLE.TECHNICAL_SUPPORT_ENGINEER,
          ROLE.PURCHASING_MANAGER,
          ROLE.HEAD_OF_SERVICE,
          ROLE.CHIEF_ENGINEER,
          ROLE.STELLAR_WARRANTY_ENGINEER,
          ROLE.STELLAR_EMPLOYEE
        ].indexOf(this.role) > -1
    ) {
      this.isPrivateMessage = ["private"];
    }
    setTimeout(() => {
      document.getElementsByClassName(
        "p-scrollpanel-content"
      )[0].scrollTop = 100000;
    }, 1000);
  },
  computed: {
    filteredMessages: function () {
      console.log(2);
      if (this.comments?.messages) {
        if (this.role === "EMPLOYEE_OF_CLIENT") {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.comments.messages = this.comments.messages.filter((msg) => {
            return msg.hidden === false;
          });
        }
        return this.comments.messages;
      } else {
        return [];
      }
    },
    myId() {
      return this.$store.getters.getId;
    },
    commentTypeList() {
      return [
        {
          name: "Публичные",
          value: "public",
        },
        {
          name: "Приватные",
          value: "private",
        },
      ];
    },
    userData() {
      return this.$store.getters.userData;
    },
    role() {
      return this.$store.getters.getRole;
    },
    uploadUrl() {
      return `${environment.apiUrl}/tasks/${this.task.id}/files`;
    },
    apiUrl() {
      return `${environment.apiUrl}`;
    },
  },
  data() {
    return {
      commentType: {},
      comments: [],
      isLoading: false,
      message: {
        val: "",
        isValid: true,
      },
      isPrivateMessage: [],
      webSocket: null,
      closed: false,
      displayConfirmation: false,
      imageTypes: ["image/png", "image/jpeg", "image/jpg"],
    };
  },
  methods: {
    filterIncomingMessage(message) {
      if (this.role === "EMPLOYEE_OF_CLIENT") {
        if (message.hidden === false) this.comments.messages.push(message);
      } else {
        this.comments.messages.push(message);
      }
    },
    moveScroll() {
      let panel = document.getElementsByClassName("p-scrollpanel-content")[0];
      panel.scrollTop = panel.scrollHeight;
    },
    readAllMessages() {
      fetch(
        `${environment.apiUrl}/chats/${this.task?.chat?.id}/messages/read`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        }
      );
      this.$emit("readAllMessages");
    },
    openConfirmation() {
      // если стоит приватное, то не показывать диалоговое окно
      if (this.isPrivateMessage.indexOf("private") === -1) {
        if (this.role === "EMPLOYEE_OF_CLIENT") {
          this.submitMessage();
        } else {
          this.displayConfirmation = true;
        }
      } else {
        this.submitMessage();
      }
    },
    closeConfirmation() {
      this.displayConfirmation = false;
    },
    closeConfirmationAndSend() {
      this.closeConfirmation();
      this.submitMessage();
    },
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;

      this.message.isValid = true;

      if (!this.message.val) {
        this.message.isValid = false;
        this.formIsValid = false;
      }
    },
    async filterComments() {
      await this.loadComments();

      this.comments.messages = this.comments.messages.filter((m) => {
        if (this.commentType.value === "public" && m.hidden === false)
          return true;
        return this.commentType.value === "private" && m.hidden === true;
      });
    },
    async displayComments(ccc) {
      if (!ccc?.messages) return;
      const filesToGet = [];

      if (this.role === "EMPLOYEE_OF_CLIENT") {
        ccc.messages = ccc.messages.filter((msg) => {
          return msg.hidden === false;
        });
      }

      ccc.messages = ccc.messages.map((c) => {
        const d = new Date(c.created);
        const p = new Date(c.posted);
        const ret = {
          ...c,
          createdText: dayjs(d).format("HH:mm DD.MM.YY"),
          postedText: dayjs(p).format("HH:mm DD.MM.YY"),
        };
        if (c.file) {
          ret.file = {
            ...c.file,
            href: this.apiUrl + "/files/" + c.file.path.replace(/\//g, "%2F"),
          };
          filesToGet.push(ret.file);
        }
        return ret;
      });

      // получим все файлы для чата
      const promiseGet = filesToGet.map((f, i) => {
        return fetch(f.href, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
          .then((response) => {
            return response.blob();
          })
          .then((response) => {
            f.obj = URL.createObjectURL(response);
          });
      });

      await Promise.all(promiseGet);
      this.comments = ccc;
      this.connectToSockets();
    },
    async loadComments() {
      if (!this.task?.id) return;
      this.isLoading = true;
      try {
        const response = await fetch(
          `${environment.apiUrl}/tasks/${this.task.id}/chat`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const ccc = await response.json();
        await this.displayComments(ccc);
      } catch (err) {
        const error = err.message || "Не получилось загрузить сообщения";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при загрузке сообщений!",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },
    connectToSockets() {
      const userData = this.$store.getters.userData;
      const socketUrl = encodeURI(
        `${environment.socketUrl}/chat/${this.comments.id}/${userData.phone}`
      ).replace("+", "");

      this.webSocket = new WebSocket(socketUrl);
      this.closed = false;

      this.webSocket.onmessage = (msg) => {
        console.log(msg.data);
        const message = JSON.parse(msg.data);
        const authorId = message.author.id;

        if (
          authorId !== this.myId &&
          !(this.role === "EMPLOYEE_OF_CLIENT" && message.hidden === true)
        ) {
          this.$emit("newUnreadMessage");
        } else {
          this.readAllMessages();
        }

        //Можно в прицнипе убрать дату, если сообщение не будет отображаться
        const d = new Date(message.created);
        const p = new Date(message.posted);
        this.filterIncomingMessage({
          ...message,
          createdText: dayjs(d).format("HH:mm DD.MM.YY"),
          postedText: dayjs(p).format("HH:mm DD.MM.YY"),
        });

        setTimeout(() => {
          document.getElementsByClassName(
            "p-scrollpanel-content"
          )[0].scrollTop = 100000;
        }, 100);

        if (message.file) {
          this.loadComments();
        }
      };

      this.webSocket.onclose = () => {
        this.closed = true;
      };
    },
    submitMessage() {
      this.validateForm();

      if (!this.formIsValid) {
        return;
      }

      const payload = {
        text: this.message.val,
        hidden: this.isPrivateMessage.indexOf("private") !== -1,
      };

      this.webSocket.send(JSON.stringify(payload));

      this.message.val = "";
    },
    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          maxWidth: 1920,
          maxHeight: 1920,
          success: resolve,
          error: reject,
        });
      });
    },
    async myUploader(event) {
      this.isLoading = true;
      let file = event.files[0];
      if (this.imageTypes.includes(file.type)) {
        file = await this.compressImage(file);
        if (file instanceof Blob) {
          file = new File([file], file.name);
        }
      }
      console.log(file);
      const data = new FormData();
      data.append("file", file);

      try {
        let response = await fetch(this.uploadUrl, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            // "Content-Type": "application/json",
          },
          body: data,
        });

        const result = await response.json();

        // send message to socket
        const payload = {
          text: this.message.val,
          hidden: this.isPrivateMessage.indexOf("private") !== -1,
          file: result,
        };

        this.webSocket.send(JSON.stringify(payload));

        this.message.val = "";
        this.$refs.uploadBtn.clear();
        this.loadComments();
      } catch (err) {
        const error = err.message || "Не получилось прикрепить файл";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при сохранении файла!",
          detail: error,
          life: 6000,
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.username {
  font-weight: bold;
}

.hidden {
  color: #494f56;
  background-color: rgb(218, 255, 219);
  border-radius: 5px;
  border-color: #ccc;
  border-width: 4px;
}

.public {
  color: #494f56;
  background-color: rgb(126, 218, 141);
  border-radius: 5px;
}

.time {
  font-family: "Arial", monospace;
  opacity: 0.5;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.offtime {
  color: blue;
}

::v-deep(.p-scrollpanel) {
  p {
    padding: 0.5rem;
    line-height: 1.5;
    margin: 0;
  }

  &.custombar1 {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--surface-b);
    }

    .p-scrollpanel-bar {
      background-color: var(--primary-color);
      opacity: 1;
      transition: background-color 0.2s;

      &:hover {
        background-color: #007ad9;
      }
    }
  }
}
.chat-created {
  border-radius: 20px;
  background-color: rgb(222, 221, 219);
}
</style>
