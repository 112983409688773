<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <div class="p-d-flex p-ai-center p-mb-2">
          <h5 class="p-m-0">
            {{ taskStatus?.name }}
          </h5>
          <div
            v-if="!permissions['change_task_priority']"
            class="p-ml-4 p-d-flex row"
          >
            <div class="p-d-flex p-ai-center p-mr-4" v-if="isCritical">
              <img
                src="/assets/critical.svg"
                style="width: 24px; height: 24px"
                title="Срочная заявка"
              />
              <div class="critical-label p-ml-2">Срочно!</div>
            </div>
            <div
              class="p-d-flex p-ai-center"
              style="color: green"
              v-if="isTaskChecked && permissions['agro_employee']"
            >
              <i class="pi pi-check" />
              <div class="p-ml-2">Заявка проверена руководителем сервиса</div>
            </div>
          </div>
        </div>
        <div>
          <Button
            class="p-mr-4 p-mb-2"
            label="Доработано"
            @click="reviseTaskCheckConfirmation"
            v-if="
              !isLoading &&
              task.revisionRole === 'ENGINEER' &&
              role === 'ENGINEER'
            "
          />
          <Button
            class="p-mr-4 p-mb-2"
            label="Подтвердить доработку"
            @click="reviseTaskConfirmDialog"
            v-if="
              !isLoading &&
              task.revisionRole === ROLE.WARRANTY_ENGINEER &&
              (role === ROLE.WARRANTY_ENGINEER ||
                role === ROLE.HEAD_OF_SERVICE ||
                role === ROLE.CHIEF_ENGINEER ||
                role === ROLE.STELLAR_WARRANTY_ENGINEER)
            "
          />
          <Button
              v-if="
              permissions['take_in_work'] &&
              ((task?.status === 'WAIT_ENGINEER_CONFIRM' &&
                task.engineer.id === myId) ||
                role === 'WARRANTY_ENGINEER' ||
                role === 'STELLAR_WARRANTY_ENGINEER')
            "
              class="p-mr-2 p-mb-2"
              label="Взять в работу"
              :disabled="isLoading"
              @click="updateStatus('TAKE IN WORK')"
          />
          <Button
            v-if="
              (task?.status === 'WAIT_OPERATOR_CONFIRM' ||
                task?.status === 'WAIT_CLIENT_CONFIRM') &&
              (role === ROLE.WARRANTY_ENGINEER ||
                role === ROLE.HEAD_OF_SERVICE ||
                role === ROLE.CHIEF_ENGINEER ||
                role === ROLE.OPERATOR ||
                role === ROLE.STELLAR_WARRANTY_ENGINEER)
            "
            class="p-mr-2 p-mb-2"
            label="Отправить на доработку"
            :disabled="isLoading"
            @click="openReviseTaskConfirmation"
          />
          <Button
            v-if="
              permissions['return_task'] &&
              task?.engineer?.id === myId &&
              ((task?.status === 'WAIT_ENGINEER_CONFIRM' &&
                role === 'ENGINEER') ||
                (task?.status === 'WORK_ENGINEER' &&
                  role === 'TECHNICAL_SUPPORT_ENGINEER'))
            "
            class="p-mr-4 p-mb-2"
            label="Возврат оператору"
            :disabled="isLoading"
            @click="openReturnTaskToOperatorConfirmation"
          />
          <Button
            v-if="
              permissions['need_details'] &&
              task?.status === 'WORK_ENGINEER' &&
              !task?.revisionRole
            "
            class="p-mr-4 p-mb-2"
            label="Нужны запчасти"
            :disabled="isLoading"
            @click="openConfirmationParts"
          />
          <Button
            v-if="
              task?.status === 'WORK_ENGINEER' &&
              task?.engineer?.id === myId &&
              !task.revisionRole
            "
            label="Возврат заявки"
            class="p-mr-4 p-mb-2"
            @click="openConfirmationReturnTask"
          />
          <Button
            v-if="
              permissions['details_purchased'] &&
              task?.status === 'PURCHASING_PARTS'
            "
            class="p-mr-4 p-mb-2"
            label="Запчасти куплены"
            :disabled="isLoading"
            @click="updateStatus('DETAILS_BOUGHT')"
          />
          <Button
            v-if="
              permissions['details_purchased'] &&
              task?.status === 'PURCHASING_PARTS'
            "
            class="p-mr-4 p-mb-2"
            label="Закупка не требуется"
            :disabled="isLoading"
            @click="askCancelBuying"
          />
          <Button
            v-if="
              permissions['close_task'] &&
              task?.status === 'WORK_ENGINEER' &&
              !task.revisionRole
            "
            class="p-mr-2 p-mb-2"
            label="Закрыть"
            :disabled="isLoading || !isChecklistChecked"
            @click="onCloseTaskHandler"
          />
          <div
            v-if="
              (role === 'ENGINEER' || role === 'TECHNICAL_SUPPORT_ENGINEER') &&
              task?.status === 'WORK_ENGINEER' &&
              !isChecklistChecked
            "
            class="p-mb-2"
          >
            Чтобы закрыть заявку, пройдите чек-лист
          </div>
          <Dropdown
            v-if="
              permissions['assign_engineer'] &&
              (task?.status === 'NEW' ||
                task?.status === 'WAIT_ENGINEER_CONFIRM' ||
                task?.status === 'RETURNED')
            "
            :options="engineers"
            v-model="currentEngineer"
            placeholder="Назначить инженера"
            optionLabel="nameText"
            class="p-mr-2 p-mb-2"
            :filter="true"
            @change="confirmAssign(currentEngineer.id)"
            :disabled="isLoading"
          >
            <template #option="slotProps">
              {{ slotProps.option.nameText }}
              <br />
              {{ slotProps.option.serviceInfo }}
            </template>
          </Dropdown>
          <Button
            class="p-mr-2 p-mb-2"
            label="Подтвердить"
            @click="confirmConfirmation"
            v-if="
              (permissions['done_task'] &&
                taskStatus?.value === 'WAIT_OPERATOR_CONFIRM') ||
              (permissions['done_task'] &&
                taskStatus?.value === 'WAIT_CLIENT_CONFIRM')
            "
            :disabled="
              isLoading ||
              (taskStatus?.value !== 'WAIT_CLIENT_CONFIRM' &&
                taskStatus?.value !== 'WAIT_OPERATOR_CONFIRM')
            "
          />
          <Button
            @click="openDoneNoSoDialog"
            label="Отработано без С.О."
            class="p-mr-2 p-mb-2"
            v-if="permissions['done_no_so']"
            :disabled="isLoading"
          />
          <Button
            class="p-mr-2 p-mb-2"
            label="Подтвердить и оценить"
            v-if="
              permissions['confirm_and_evaluate'] &&
              taskStatus?.value === 'WAIT_CLIENT_CONFIRM'
            "
            @click="moodConfirmation = true"
          >
          </Button>
          <Button
            class="p-mr-2 p-mb-2 p-button-warning"
            label="Отменить"
            @click="askForceClose"
            v-if="
              !isLoading &&
              permissions['cancel_task'] &&
              taskStatus?.value !== 'CANCELED' &&
              taskStatus?.value !== 'DONE'
            "
          />
        </div>
        <div
          class="p-d-flex p-ai-center"
          v-if="permissions['change_task_priority']"
        >
          <img
            src="/assets/critical.svg"
            style="width: 24px; height: 24px"
            title="Cрочная заявка"
          />
          <div>Срочная заявка</div>
          <Checkbox
            class="p-ml-3"
            id="check"
            name="check"
            v-model="isCritical"
            :binary="true"
            @change="changeTaskCriticalValue"
            :disabled="isLoading"
          />
        </div>
        <div
          class="p-d-flex p-ai-center"
          v-if="permissions['change_task_priority']"
        >
          <i class="pi pi-check p-ml-1" />
          <div class="p-ml-1">Проверено руководителем сервиса</div>
          <Checkbox
            class="p-ml-3"
            id="taskChecked"
            name="taskChecked"
            v-model="isTaskChecked"
            @change="changeTaskCheckedValue"
            :binary="true"
            :disabled="isLoading"
          />
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-6">
      <div class="card p-fluid">
        <h4>Информация</h4>
        <p>
          № Заявки: <span class="data">{{ task?.number }}</span>
        </p>
        <p>Дата оформления: {{ task?.createdText }}</p>

        <p class="p-ai-center p-d-flex p-flex-row" v-if="task?.ended">
          <DatePicker
            @dayclick="dayClickHandler"
            v-model="date"
            class="p-mr-2"
            v-if="role === 'OPERATOR'"
            :max-date="maxCloseDate"
            :min-date="minCloseDate"
          >
            <template v-slot="{ togglePopover }">
              <Button
                icon="pi pi-pencil"
                @click="togglePopover()"
                class="p-button-success pencil-button"
              />
            </template>
          </DatePicker>
          Дата закрытия: {{ getFormattedDate(task?.ended) }}
        </p>

        <p v-if="task?.closed">
          Дата закрытия инженером: {{ getFormattedDate(task?.closed) }}
        </p>

        <p v-if="task?.closed">Время работы по заявке: {{ getTimeDiff }}</p>
        <p>
          <Button
            v-if="
              permissions['assign_engineer'] &&
              task?.status !== 'CANCELED' &&
              task?.status !== 'DONE'
            "
            icon="pi pi-pencil"
            class="p-button-success p-mr-2 pencil-button"
            @click="changeEditEngineerMode"
          ></Button>
          <span class="p-text-bold">Ответственный инженер:</span>
          {{
            (task?.engineer
              ? task?.engineer?.lastname + " " + task?.engineer?.firstname
              : "Не назначен") + " "
          }}
          <a :href="'tel:' + task?.engineer?.phone">{{
            task?.engineer?.phone
          }}</a>
        </p>
        <p v-if="editEngineerMode">
          <Dropdown
            :options="engineers"
            v-model="currentEngineer"
            optionLabel="nameText"
            :disabled="isLoading"
            :filter="true"
            @change="confirmAssign(currentEngineer.id)"
          >
            <template #option="slotProps">
              {{ slotProps.option.nameText }}
              <br />
              {{ slotProps.option.serviceInfo }}
            </template>
          </Dropdown>
        </p>
        <div>
          <Button
            v-if="
              role === 'OPERATOR' &&
              task?.status !== 'CANCELED' &&
              task?.status !== 'DONE' &&
              !taskHeaderEditingMode
            "
            icon="pi pi-pencil"
            class="p-button-success pencil-button p-mr-2"
            @click="editTaskHeader"
          ></Button>
          <Button
            v-if="taskHeaderEditingMode"
            icon="pi pi-check"
            class="p-button-success pencil-button p-mr-2"
            @click="submitHeader"
          ></Button>
          Заголовок заявки: <span>{{ task?.header }}</span>
        </div>
        <p>
          <InputText v-model="taskHeader" v-if="taskHeaderEditingMode" />
        </p>

        <p style="white-space: pre-line">
          <Button
            v-if="
              (role === 'OPERATOR' || role === 'CHIEF_ENGINEER') &&
              task?.status !== 'CANCELED' &&
              task?.status !== 'DONE' &&
              !taskDescriptionEditingMode
            "
            icon="pi pi-pencil"
            class="p-button-success pencil-button p-mr-2"
            @click="editTaskDesc"
          ></Button>
          <Button
            v-if="taskDescriptionEditingMode"
            icon="pi pi-check"
            class="p-button-success pencil-button p-mr-2"
            @click="submitDesc"
          ></Button>
          Описание неисправности: {{ task?.description }}
        </p>
        <p>
          <Textarea
            v-model="taskDesc.val"
            v-if="taskDescriptionEditingMode"
            :class="!taskDesc.isValid ? 'p-invalid' : ''"
          />
        </p>
        <p>
          <Button
            v-if="
              (role === 'OPERATOR' ||
                (role === 'ENGINEER' && task?.engineer?.id === userData.id)) &&
              !(role === 'OPERATOR' && task?.status === 'WORK_ENGINEER') &&
              task?.status !== 'CANCELED' &&
              task?.status !== 'DONE'
            "
            icon="pi pi-pencil"
            class="p-button-success pencil-button p-mr-2"
            @click="toggleMenuByRef($event, 'menuRepairList')"
          />
          Вид ремонта:
          <span v-if="!isLoading"
            >{{ getNameByType(task?.workType) }}
            <!--            Нестандартная ситуация-->
          </span>
        </p>
        <p>
          <Button
            v-if="
              (role === 'OPERATOR' ||
                (role === 'ENGINEER' && task?.engineer?.id === userData.id)) &&
              !(role === 'OPERATOR' && task?.status === 'WORK_ENGINEER') &&
              task?.status !== 'CANCELED' &&
              task?.status !== 'DONE'
            "
            icon="pi pi-pencil"
            class="p-button-success pencil-button p-mr-2"
            @click="toggleMenuByRef($event, 'menuChecklistTemplate')"
          />
          Шаблон чеклиста: {{ currentChecklistTemplate?.name }}
        </p>
        <p>
          <span v-if="currentEstimation" class="p-d-flex p-ai-center">
            Оценка: <img :src="currentEstimation" />
          </span>
          <span v-else> Оценка: Не оценена </span>
        </p>
        <p v-if="task?.regionId">
          <Button
            v-if="
              permissions['change_task_region_id'] &&
              menuClientRegions.length &&
              task?.status !== TASK_STATUSES.CANCELED &&
              task?.status !== TASK_STATUSES.DONE
            "
            icon="pi pi-pencil"
            class="p-button-success pencil-button p-mr-2"
            @click="toggleMenuByRef($event, 'menuClientRegions')"
          />
          {{ getRegionById(task?.regionId).name }}
        </p>
        <ConfirmationDialog
            :on-cancel="closeConfirmationRepairItems"
            :on-confirm="closeConfirmationAndSelectRepairItems"
            :message="getRepairItemsAlert()"
            :visible="displayConfirmationRepairItems"/>

        <div class="p-grid">
          <div class="p-col-6">
            <h5>Клиент
              <Button
                  v-if="
                  (role === 'OPERATOR') && task.status !== 'DONE'
                "
                  icon="pi pi-pencil"
                  class="p-button-success pencil-button p-mr-2"
                  @click="clientEditMode = !clientEditMode"
              ></Button>
            </h5>
            <div class="p-field">
              <Dropdown
                  v-if="clientEditMode"
                  v-model="currentClient"
                  :options="clientList"
                  optionLabel="fullName"
                  :filter="true"
                  emptyFilterMessage="Не удаётся найти такую компанию"
                  @change="confirmChangeCompany(currentClient)"
              >
              </Dropdown>
            </div>
            <div class="p-field">
              <label>Название:</label>
              <br />
              <div class="p-d-flex p-align-center">
                <router-link
                  :to="{
                    name: 'ClientDetail',
                    params: { id: task?.company?.uid },
                  }"
                >
                  <span v-if="permissions['see_nonofficial_name']">
                    {{ task?.company?.name }}
                  </span>
                  <span v-else>
                    {{ task?.company?.officialName }}
                  </span>
                </router-link>
                <GetDebtButton
                  v-if="permissions['get_debt']"
                  :client-uid="task?.company?.uid"
                  class="p-ml-2"
                  mini
                />
              </div>
            </div>
            <div class="p-field">
              <label>Район:</label>
              <br />
              {{ task?.company?.district }}
            </div>
            <div class="p-field">
              <label>ИНН:</label>
              <br />
              {{ task?.company?.inn }}
            </div>
            <div class="p-field">
              <label>Контактное лицо:</label>
              <br />
              <span
                class="employee main"
                :class="{ blocked: task?.employee?.block }"
              >
                {{ task?.employee?.lastname }} {{ task?.employee?.firstname }}
              </span>

              <a :href="'tel:' + task?.employee?.phone">{{
                " " + task?.employee?.phone
              }}</a>
            </div>

            <div
              class="p-field"
              v-for="employee in task.otherEmployees"
              :key="employee.id"
            >
              <label>Контактное лицо:</label>
              <br />
              {{ employee.lastname }} {{ employee.firstname }}
              <a :href="'tel:' + employee.phone">{{ employee.phone }}</a>
            </div>

            <div class="p-field">
              <TaskContractsList
                :contracts="task?.contract"
                class="p-d-flex p-flex-column"
                style="gap: 4px"
              />
            </div>
          </div>

          <div class="p-col-6">
            <h5>
              Техника
              <!--              Нестандартная ситуация с access manager-->
              <Button
                v-if="
                  role === 'OPERATOR' ||
                  (role === 'ENGINEER' &&
                    task?.engineer?.id === userData.id &&
                    !(
                      task?.status === 'WAIT_CLIENT_CONFIRM' ||
                      task?.status === 'WAIT_OPERATOR_CONFIRM' ||
                      task?.status === 'DONE' ||
                      task?.status === 'CANCELED'
                    ) &&
                    !isLoading)
                "
                icon="pi pi-pencil"
                class="p-button-success pencil-button p-mr-2"
                @click="changeEditTechnicMode"
              ></Button>
            </h5>
            <div class="p-field">
              <Dropdown
                v-if="editTechnicMode"
                v-model="currentTechnic"
                :options="dropdownTechnicItems()"
                :filter="true"
                optionLabel="label"
                emptyFilterMessage="Не удаётся найти такую технику"
                @hide="confirmAssignTechnics(currentTechnic?.id)"
              >
              </Dropdown>
            </div>
            <div v-if="task.otherTechnic === false">
              <div class="p-field">
                <label>Тип техники:</label>
                <br />
                {{ task?.technic?.technicTypeInfo?.name }}
              </div>
              <div class="p-field">
                <label>Производитель техники:</label>
                <br />
                {{ task?.technic?.technicProducerInfo?.name }}
              </div>
              <div class="p-field">
                <label>Модель техники:</label>
                <br />
                {{ task?.technic?.technicModelInfo?.name }}
              </div>
              <div class="p-field">
                <label>Серийный номер:</label>
                <br />
                {{ task?.technic?.series }}
              </div>
            </div>
            <div class="p-field" v-else>
              <label>Тип техники:</label>
              <br />
              Иное
            </div>
          </div>
        </div>
      </div>
      <div
        class="card p-fluid p-mt-3"
        v-if="
          permissions['see_ptc'] &&
          task?.workType === 'WARRANTY_REPAIR' &&
          task.status !== 'CANCELED'
        "
      >
        <div class="">
          <h5>
            Информация о ПТХ
            <span v-if="task?.warrantyEngineer"
              >({{ task?.warrantyEngineer?.lastname }}
              {{ task?.warrantyEngineer?.firstname }})</span
            >
          </h5>
          <span class="p-mr-5">
            <label class="p-mr-3">Не ПТХ</label>
            <Checkbox
              :disabled="!permissions['change_ptc'] || isLoading || typePtc || role === 'STELLAR_EMPLOYEE'"
              @click="openConfirmationPtc('notPtc')"
              v-model="notPtc"
              :binary="true"
            />
          </span>
          <span class="p-mr-5">
            <label class="p-mr-3">ПТХ</label>
            <Checkbox
              :disabled="!permissions['change_ptc'] || isLoading || typePtc || role === 'STELLAR_EMPLOYEE'"
              @click="openConfirmationPtc('ptc')"
              v-model="ptc"
              :binary="true"
            />
          </span>
          <span
              v-if="ptc && task?.technic?.technicProducerInfo.name === 'STELLAR' && role === 'STELLAR_WARRANTY_ENGINEER'"
              class="p-mr-5">
            <label class="p-mr-3">Stellar</label>
            <Checkbox
                @change="displayConfirmationPtc = true"
                :disabled="!permissions['change_ptc'] || isLoading || typePtc || role === 'STELLAR_EMPLOYEE'"
                v-model="stellar"
                :binary="true"
            />
          </span>
          <div class="p-mt-5"
               v-if="isPtcOrStellarVerified && role !== 'STELLAR_EMPLOYEE'">
            <div>
              <div>Номер ПТХ</div>
              <InputText
                v-model="numberPtc"
                :disabled="!permissions['change_ptc'] || isLoading"
              />
            </div>
            <div class="p-mt-2">
              <Button
                :disabled="!permissions['change_ptc'] || isLoading"
                @click="submitNumberPtc()"
                label="Сохранить"
              />
            </div>

            <div class="p-d-flex p-mt-6" v-if="role !== 'STELLAR_EMPLOYEE'">
              <Button
                @click="setTypePtc('FACTORY')"
                class="p-mr-2"
                :disabled="!permissions['change_ptc'] || isLoading"
              >
                <span class="p-button-label">Виноват завод</span>
                <i v-if="typePtc === 'FACTORY'" class="pi pi-check" />
              </Button>
              <Button
                @click="setTypePtc('WORKER')"
                class="p-mr-2"
                :disabled="!permissions['change_ptc'] || isLoading"
              >
                <span class="p-button-label">Виноват работник</span>
                <i v-if="typePtc === 'WORKER'" class="pi pi-check" />
              </Button>
              <Button
                @click="setTypePtc('CLIENT')"
                :disabled="!permissions['change_ptc'] || isLoading"
              >
                <span class="p-button-label">Виноват клиент</span>
                <i v-if="typePtc === 'CLIENT'" class="pi pi-check" />
              </Button>
            </div>
          </div>

          <div class="p-mt-5">
            <div>
              <div class="p-mb-1">Комментарий</div>
              <Textarea
                :disabled="!permissions['change_ptc'] || isLoading"
                :autoResize="true"
                rows="5"
                cols="30"
                v-model="textPtc"
              />
            </div>
            <div class="p-mt-2">
              <Button
                :disabled="!permissions['change_ptc'] || isLoading"
                @click="submitTextPtc()"
                label="Сохранить"
              />
            </div>
            <div class="p-mt-2"
                 v-if="stellar && role === 'STELLAR_EMPLOYEE' && task.statusPtc === 'NOT_VERIFIED_STELLAR'">
              <Button
                :disabled="!permissions['change_ptc'] || isLoading"
                @click="toggleVerification()"
                label="Завершить проверку"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-6">
      <div class="card p-fluid">
        <TabView>
          <TabPanel>
            <template #header>
              <span>Чек-лист</span>
            </template>
            <task-check-list
              ref="checklistComponent"
              @loaded-checklist="loadedChecklist"
              :checklistFromParent="task?.checklistInfo"
              :task="task"
              :key="task?.id"
            />
          </TabPanel>
          <TabPanel
            :disabled="
              role === 'TECHNICAL_SUPPORT_ENGINEER'
            "
          >
            <template #header>
              <span>Команда</span>
            </template>
            <TaskCommandWork
              ref="commandWorkComponent"
              :task="task"
              :taskEngineersFromParent="task?.engineers"
              :key="task?.id"
            />
          </TabPanel>
          <TabPanel>
            <template #header>
              <span class="p-pr-4">Запчасти</span>
              <Badge
                :value="currentDetailsUseCount"
                severity="success"
                class="p-ml-3 badge-numbers"
              ></Badge>
            </template>
            <TaskDetails
              :task="task"
              :key="task?.id"
              @detailsUseCount="detailsUseCount"
            />
          </TabPanel>
          <TabPanel>
            <template #header>
              <div>
                <span class="p-pr-4">Чат</span>
                <Badge
                  :value="unreadMessagesCount"
                  severity="success"
                  class="p-ml-3 badge-numbers"
                ></Badge>
              </div>
            </template>
            <task-comments
              :task="task"
              :key="task?.id"
              :commentsFromParent="task?.chat"
              ref="TaskCommentsComponent"
              @readAllMessages="unreadMessagesCount = 0"
              @newUnreadMessage="unreadMessagesCount += 1"
            />
          </TabPanel>
          <TabPanel>
            <template #header>
              <span>Файлы</span>
            </template>
            <task-files
              :task="task"
              :key="task?.id"
              :filesFromParent="task?.files"
            />
          </TabPanel>
          <TabPanel>
            <template #header>
              <span>История</span>
            </template>
            <task-history :task="task" :key="task?.id" />
          </TabPanel>
          <TabPanel>
            <template #header>
              <span>Журнал</span>
            </template>
            <task-logbook :task-id="task.id" :key="task?.id" />
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>
  <ConfirmationDialog
      :on-cancel="closeConfirmationPtc"
      :on-confirm="closeConfirmationAndSendPtc"
      :message="'Вы уверены, что хотите сменить тип заявки?'"
      :visible="displayConfirmationPtc"/>

  <ConfirmationDialog
      :on-cancel="closeConfirmationTech"
      :on-confirm="closeConfirmationAndSelectTech"
      :message="getTechnicAssignAlert()"
      :visible="displayConfirmationTechnics"/>
  <Dialog
      @hide="closeCompanyChangeConfirmation"
      v-model:visible="displayCompanyPopup"
      :style="{ width: '50%'}"
      :modal="true"
  >
    <div class="confirmation-content" style="flex-direction: column">
          <span>Для изменения компании на <router-link
              :to="{ name: 'ClientDetail', params: { id: currentClient.uid } }"
              target="_blank">{{ `"${currentClient.name}"` }}</router-link></span>
      <div style="display: flex; flex-direction: column; align-items: center; text-align: center; width: 90%">
        <div style="margin-bottom: 1rem; min-width: 100%">
          <p style="font-weight: bold">Выберите контактное лицо</p>
          <Dropdown
              style="width: 100%"
              v-model="newEmployee"
              :options="companyEmployees"
              option-label="fullName"
              emptyFilterMessage="Не удаётся найти такого пользователя"
              :filter="true"
          />
        </div>
        <div style="min-width: 100%">
          <p style="font-weight: bold">Выберите технику</p>
          <div style="flex-direction: row; display: flex">
            <Dropdown
                style="flex-grow: 1"
                v-model="newTechnic"
                :options="companyTechnics"
                option-label="techLabel"
                emptyFilterMessage="Не удаётся найти такую технику"
                :filter="true"
            />
            <Button
                icon="pi pi-spinner"
                @click="loadNewCompanyTechnics"
            />
          </div>
        </div>
      </div>
      <ConfirmationDialog
          :on-cancel="toggleCompanyChangeConfirmation"
          :on-confirm="closeConfirmationAndChangeClient"
          :message="`Вы уверены, что хотите сменить компанию на ${currentClient.name}?`"
          :visible="displayCompanyConfirmation"/>

    </div>
    <template #footer>
      <Button
          label="Отмена"
          icon="pi pi-times"
          @click="closeCompanyChangeConfirmation"
          class="p-button-text"
      />
      <Button
          label="Продолжить"
          icon="pi pi-check"
          @click="toggleCompanyChangeConfirmation"
          class="p-button-text"
          :disabled="!(newTechnic && newEmployee)"
      />
    </template>
  </Dialog>

  <ConfirmationDialog
      :on-cancel="closeConfirmation"
      :on-confirm="closeConfirmationAndSelect"
      :message="getEngineerAssignAlert"
      :visible="displayConfirmation"/>

  <ConfirmationDialog
      :on-cancel="toggleVerification"
      :on-confirm="verifyStellar"
      :message="'Вы уверены, что хотите завершить проверку?'"
      :visible="displayVerification"/>

  <Dialog
    header="Оцените работу!"
    v-model:visible="moodConfirmation"
    :style="{ width: '350px' }"
    :modal="true"
  >
    <div class="confirmation-content">
      <div class="moods" @click="confirmAndEvaluate(0)">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <circle cx="15.5" cy="9.5" r="1.5" />
          <circle cx="8.5" cy="9.5" r="1.5" />
          <path
            d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm0-6c-2.33 0-4.32 1.45-5.12 3.5h1.67c.69-1.19 1.97-2 3.45-2s2.75.81 3.45 2h1.67c-.8-2.05-2.79-3.5-5.12-3.5z"
          />
        </svg>
      </div>
      <div class="moods" @click="confirmAndEvaluate(50)">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M9 15.5h6v1H9v-1z" />
          <circle cx="15.5" cy="9.5" r="1.5" />
          <circle cx="8.5" cy="9.5" r="1.5" />
          <path
            d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
          />
        </svg>
      </div>
      <div class="moods" @click="confirmAndEvaluate(100)">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
          />
        </svg>
      </div>
    </div>
  </Dialog>

  <TextareaDialog
    :is-visible-prop="displayReturnTaskToOperatorConfirmation"
    main-text="Вы точно хотите вернуть эту заявку оператору?"
    placeholder-text="Введите причину возврата"
    @closeDialog="closeConfirmationReturnTaskToOperator"
    @update="closeReturnTaskToOperatorConfirmationAndPost($event)"
  />
  <TextareaDialog
    :is-visible-prop="displayReviseTaskConfirmation"
    main-text="Вы точно хотите отправить эту заявку на доработку?"
    placeholder-text="Введите причину возврата"
    @closeDialog="closeReviseTaskConfirmation"
    @update="reviseTask"
  />
  <TextareaDialog
    :is-visible-prop="displayReturnTaskConfirmation"
    main-text="Вы точно хотите вернуть эту заявку?"
    placeholder-text="Введите причину возврата"
    @closeDialog="closeConfirmationReturnTask"
    @update="displayReturnTaskConfirmationAndPost($event)"
  />
  <TextareaDialog
    main-text="Вы уверены, что закупка не требуется? Напишите свой комментарий:"
    :is-visible-prop="displayConfirmationCancelBuying"
    placeholder-text="Введите комментарий"
    @closeDialog="closeCancelBuyingConfirmation"
    @update="closeConfirmationAndCancelBuying($event)"
  />
  <TextareaDialog
    :is-visible-prop="displayConfirmationForceCloseTask"
    main-text="Вы уверены, что хотите принудительно завершить задачу? Напишите
                причину:"
    placeholder-text="Введите причину"
    @closeDialog="closeForceCloseTask"
    @update="closeForceCloseTaskAndCloseTask($event)"
  />
  <TextareaDialog
    :is-visible-prop="displayConfirmationParts"
    main-text="Укажите артикул детали"
    placeholder-text="Введите артикул детали"
    @closeDialog="closeConfirmationParts"
    @update="closeConfirmationPartsAndUpdate($event)"
  />

  <ConfirmDialog group="taskDetailDialog"></ConfirmDialog>
  <ConfirmDialog group="confirmConfirmationDialog"></ConfirmDialog>
  <ConfirmDialog group="emptyRealizationDialog"></ConfirmDialog>
  <ConfirmDialog group="changeDateCloseDialog"></ConfirmDialog>
  <ConfirmDialog group="detailsWarning" />
  <ConfirmDialog group="doneNoSoDialog"></ConfirmDialog>
  <ConfirmDialog group="reviseTaskCheckDialog"></ConfirmDialog>
  <ConfirmDialog group="reviseTaskConfirmDialog"></ConfirmDialog>

  <Menu ref="menuRepairList" :model="menuRepairItems" :popup="true" />
  <Menu
    ref="menuChecklistTemplate"
    :model="menuChecklistTemplates"
    :popup="true"
  />
  <Menu ref="menuClientRegions" :model="menuClientRegions" :popup="true" />

  <ScrollTopArrow />
</template>

<script>
import { environment } from "@/config";
import {
  getRoleText,
  getTaskStatusText,
  ROLES_LIST_TASK_STATUSES,
  TASK_STATUSES,
  ROLE,
} from "@/models/roles";
import TaskComments from "@/views/tasks/TaskComments";
import TaskHistory from "@/views/tasks/TaskHistory";
import TaskCheckList from "@/views/tasks/TaskCheckList";
import TaskFiles from "@/views/tasks/TaskFiles";
import dayjs from "dayjs";
import TaskCommandWork from "@/views/tasks/TaskCommandWork";
import TaskDetails from "../tasks/TaskDetails";
import TaskLogbook from "../tasks/TaskLogbook";
import ScrollTopArrow from "../TableComponents/ScrollTopArrow";
import { Calendar, DatePicker } from "v-calendar";
import TextareaDialog from "@/views/operator/components/TextareaDialog";
import { mapGetters } from "vuex";
import tasksClient from "@/api/tasksClient";
import GetDebtButton from "@/components/GetDebtButton";
import TaskContractsList from "@/views/tasks/TaskContractsList.vue";
import ConfirmationDialog from "@/views/operator/components/ConfirmationDialog.vue";

export default {
  name: "TaskDetail",
  components: {
    TaskContractsList,
    GetDebtButton,
    TextareaDialog,
    ScrollTopArrow,
    TaskLogbook,
    TaskDetails,
    TaskCommandWork,
    TaskFiles,
    TaskCheckList,
    TaskHistory,
    TaskComments,
    Calendar,
    DatePicker,
    ConfirmationDialog
  },

  async created() {
    await this.loadTask(this.$route.params.id);
    await this.loadTemplates();
    await this.loadedChecklist(this.task.checklistInfo);
  },
  data() {
    return {
      newEmployee: null,
      newTechnic: null,
      showPopup: false,
      companyEmployees: [],
      companyTechnics: [],
      ROLE,
      TASK_STATUSES,
      displayReturnTaskToOperatorConfirmation: false,
      displayReviseTaskConfirmation: false,
      displayReturnTaskConfirmation: false,
      date: null,
      engineerWarnedAboutDetails: false,
      currentDetailsUseCount: 0,
      ptcStatuses: ["PTC", "PTC_CLOSED", "NOT_VERIFIED_STELLAR", "VERIFIED_STELLAR"],
      unreadMessagesCount: null,
      id: null,
      notPtc: null,
      typePtc: null,
      textPtc: null,
      numberPtc: null,
      ptc: null,
      stellar: null,
      isLoading: false,
      currentChecklistTemplate: null,
      task: {},
      checklistTemplatesList: [],
      currentEngineer: { id: null },
      editEngineerMode: false,
      currentTechnic: { id: null },
      editTechnicMode: false,
      currentClient: { uid: null, name: null },
      clientEditMode: false,
      taskStatus: null,
      taskHeader: null,
      taskDesc: { val: null, isValid: true },
      taskHeaderEditingMode: false,
      taskDescriptionEditingMode: false,
      displayConfirmation: false,
      displayConfirmationTechnics: false,
      displayCompanyPopup: false,
      displayCompanyConfirmation: false,
      displayConfirmationForceCloseTask: false,
      displayConfirmationParts: false,
      displayConfirmationPtc: false,
      displayVerification: false,
      displayConfirmationRepairItems: false,
      displayConfirmationCancelBuying: false,
      whomToAssignId: null,
      whomToAssignTechId: null,
      newWorkType: null,
      isChecklistChecked: false,
      technicList: [],
      clientList: [],
      cancelBuyingReason: "",
      isCritical: false,
      isTaskChecked: false,
      moodConfirmation: false,
      statusesWithReasons: [
        "FORCE_CLOSE",
        "DETAILS_NOT_NECESSARY",
        "DETAILS",
        "RETURN OP",
      ],
      toastStatuses: { success: 'success', error: 'error' }
    };
  },
  computed: {
    isPtcOrStellarVerified() {
      return this.ptc === true || this.task.statusPtc === 'VERIFIED_STELLAR'
    },
    ...mapGetters({
      token: "token",
      myId: "getId",
      role: "getRole",
      userData: "userData",
      workTypeList: "data/workTypes",
      getRegionById: "data/getRegionById",
      permissions: "getPermissions",
      engineers: "myprofiles/engineers",
    }),
    getEngineerAssignAlert() {
      const e = this.$store.getters["myprofiles/engineers"].find((e) => e.id === this.whomToAssignId)
      if (e)
        return `Вы уверены, что хотите назначить ${e.lastname} ${e.firstname}?`;
      return '-'
    },
    menuRepairItems() {
      return this.workTypeList.map((t) => {
        return {
          label: `${t.name}`,
          command: () => {
            this.confirmRepairItems(t.value);
          },
        };
      });
    },
    menuChecklistTemplates() {
      const ctlForSending = this.checklistTemplatesList.filter((ctl) => {
        return ctl?.type === this.task?.workType;
      });
      return ctlForSending.map((t) => {
        return {
          label: `${t.name}`,
          command: () => {
            this.changeChecklistConfirmation(t);
          },
        };
      });
    },
    menuClientRegions() {
      return this.task?.company?.regionIds
        .map((regionId) => {
          const region = this.$store.getters["data/getRegionById"](regionId);
          if (!region) return false;
          return {
            label: `${region.name}`,
            command: () => {
              this.confirmChangeTaskRegion(region.uid);
            },
          };
        })
        .filter(Boolean);
    },
    minCloseDate() {
      return dayjs().subtract(60, "days").valueOf();
    },
    maxCloseDate() {
      // if (this.task?.workType !== "WARRANTY_REPAIR") {
      return dayjs().add(60, "days").valueOf();
      // }
      // return null;
    },
    getTimeDiff() {
      const oneHour = 1000 * 60 * 60;
      let hours =
        (new Date(this.task?.closed) - new Date(this.task?.created)) / oneHour;
      let days = Math.floor(hours / 24);
      hours = Math.floor(hours % 24);
      return `${days} д., ${hours} ч.`;
    },
    currentEstimation() {
      switch (this.task?.estimation) {
        case 100:
          return "/assets/moods/goodMood.svg";
        case 50:
          return "/assets/moods/normalMood.svg";
        case 0:
          return "/assets/moods/badMood.svg";
        default:
          return null;
      }
    },
    engineers() {
      return this.$store.getters["myprofiles/engineers"].map((e) => {
        return {
          nameText: `${e.firstname} ${e.lastname}`,
          serviceInfo: `[${getRoleText(e.role)}] Заявок: ${e.taskCount}`,
          id: e.id,
        };
      });
    },
  },
  methods: {
    addToast(severity, summary, detail) {
      this.$toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        life: 6000,
      })
    },
    dayClickHandler(data) {
      if (!data.isDisabled) {
        this.openEndedDateChangeConfirmation();
      }
    },
    openDoneNoSoDialog() {
      this.$confirm.require({
        message:
          "Сервисный отчёт создан не будет. Вы действительно хотите подтвердить заявку?",
        group: "doneNoSoDialog",
        header: "Внимание!",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Да",
        rejectLabel: "Нет",
        accept: () => {
          this.updateStatus("DONE_NO_SO");
        },
      });
    },
    openEndedDateChangeConfirmation() {
      this.$confirm.require({
        message: "Вы действительно хотите изменить дату закрытия заявки?",
        group: "changeDateCloseDialog",
        header: "Внимание!",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Да",
        rejectLabel: "Нет",
        accept: () => {
          this.updateEndedDate();
        },
        reject: () => {
          this.date = this.task.ended;
          this.$confirm.close();
        },
      });
    },
    onCloseTaskHandler() {
      let allDetailsUsed = true;
      this.task.details?.forEach((detail) => {
        if (detail.useCount === 0) {
          allDetailsUsed = false;
        }
      });
      if (!!allDetailsUsed || this.engineerWarnedAboutDetails) {
        this.updateStatus("CLOSE");
      } else {
        this.engineerWarnedAboutDetails = true;
        this.$confirm.require({
          message: "Не забудьте заполнить вкладку запчасти!",
          group: "detailsWarning",
          header: "Внимание",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Ок",
          rejectClass: "p-d-none",
        });
      }
    },
    async displayReturnTaskConfirmationAndPost(event) {
      this.displayReturnTaskConfirmation = false;
      this.isLoading = true;

      try {
        await fetch(
          `${environment.apiUrl}/v2/tasks/${this.task.id}/return_task`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({ text: event }),
          }
        );
        await this.loadTask(this.$route.params.id);
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },
    async reviseTask(text) {
      this.displayReviseTaskConfirmation = false;
      this.isLoading = true;

      try {
        await fetch(
          `${environment.apiUrl}/v2/tasks/${this.task.id}/revise_task`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({ text: text }),
          }
        );
        await this.loadTask(this.task.id);
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
    },
    async reviseTaskCheck() {
      this.isLoading = true;
      try {
        await fetch(
          `${environment.apiUrl}/v2/tasks/${this.task.id}/revise_task_check`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "application/json",
            },
            method: "POST",
          }
        );
        await this.loadTask(this.task.id);
      } finally {
        this.isLoading = false;
      }
    },
    async reviseTaskConfirm() {
      this.isLoading = true;
      try {
        await fetch(
          `${environment.apiUrl}/v2/tasks/${this.task.id}/revise_task_confirm`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "application/json",
            },
            method: "POST",
          }
        );
        await this.loadTask(this.task.id);
      } finally {
        this.isLoading = false;
      }
    },
    detailsUseCount(data) {
      this.currentDetailsUseCount = data;
    },
    getFormattedDate(date) {
      return dayjs(date).format("HH:mm DD.MM.YY");
    },
    emptyRealizationConfirmation() {
      this.$confirm.require({
        message: "Вы уверены, что хотите подтвердить заявку без реализации?",
        group: "emptyRealizationDialog",
        header: "Пустая информация о реализации",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Да",
        rejectLabel: "Нет",
        accept: () => {
          this.updateStatus("DONE");
        },
      })
    },
    confirmConfirmation() {
      this.$confirm.require({
        message: "Вы действительно хотите подтвердить заявку?",
        group: "confirmConfirmationDialog",
        header: "Внимание!",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Да",
        rejectLabel: "Нет",
        accept: () => {
          if (this.task.workType === 'COMMERCIAL_REPAIR' && !this.task.realizationDescription) {
            this.emptyRealizationConfirmation()
          } else this.updateStatus("DONE");
        },
      });
    },
    changeEditEngineerMode() {
      this.editEngineerMode = !this.editEngineerMode;
    },
    changeEditTechnicMode() {
      this.editTechnicMode = !this.editTechnicMode;
    },

    setTypePtc(type) {
      this.typePtc = type;
      this.submitTypePtc();
    },
    async updateEndedDate() {
      await this.updateTask(() => this.task.ended = dayjs(this.date)
              .hour(dayjs().hour())
              .minute(dayjs().minute())
              .toISOString(),
          () => { this.addToast(this.toastStatuses.success, "Дата успешно обновлена") },
          () => this.addToast(this.toastStatuses.error, "Ошибка обновления даты", "Необходимо выбрать дату после закрытия инженером")
      )
      this.date = null;
    },

    async updateTask(updateFunction, onSuccess, onError) {
      this.isLoading = true;
      const taskId = this.$route.params.id;

      try {
        updateFunction();
        const res = await fetch(`${environment.apiUrl}/tasks/${this.task?.id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
            "Content-Type": "application/json",
          },
          method: "PUT",
          body: JSON.stringify(this.task),
        });

        if (!res.ok) {
          onError(res.status);
          return;
        }

        onSuccess();
      } catch (err) {
        onError(err);
      } finally {
        this.isLoading = false;
        await this.loadTask(taskId);
      }
    },

    async updateDescription() {
      this.taskDesc.isValid = true;

      if (this.taskDesc.val.length > 300) {
        this.taskDesc.isValid = false;
        this.addToast(
            this.toastStatuses.error,
            "Ошибка при смене описания неисправности!",
            "Количество символов не может быть больше 300!"
        );
        return;
      }

      await this.updateTask(() => this.task.description = this.taskDesc.val, () => {},(err) => console.log(err))
    },
    async updateHeader() {
      await this.updateTask(() => this.task.header = this.taskHeader, () => {}, (err) => console.log(err))
    },

    submitPtcStatus() {
      switch (true) {
        case this.stellar:
          this.task.statusPtc = 'NOT_VERIFIED_STELLAR';
          break;
        case this.ptc:
          this.task.statusPtc = 'PTC';
          break;
        default:
          this.task.statusPtc = 'NOT_PTC';
      }
      this.task.typePtc = null;
      this.task.textPtc = null;
      this.task.numberPtc = null;
      this.submitPtc();
    },

    submitTypePtc() {
      this.task.typePtc = this.typePtc;
      this.task.statusPtc = null;
      this.task.textPtc = null;
      this.task.numberPtc = null;
      this.submitPtc();
    },

    submitTextPtc() {
      this.task.textPtc = this.textPtc;
      this.task.statusPtc = null;
      this.task.numberPtc = null;
      this.task.typePtc = null;
      this.submitPtc();
    },

    submitNumberPtc() {
      this.task.numberPtc = this.numberPtc;
      this.task.statusPtc = null;
      this.task.typePtc = null;
      this.task.textPtc = null;
      this.submitPtc();
    },

    async submitPtc() {
      this.isLoading = true;
      try {
        const response = await fetch(
          `${environment.apiUrl}/tasks/${this.task?.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "application/json",
            },
            method: "PUT",
            body: JSON.stringify(this.task),
          }
        );
        if (response.status !== 200) {
          throw new Error("Ошибка обновления статуса ПТХ!");
        }
        this.addToast(this.toastStatuses.success, "ПТХ изменен", "Информация о ПТХ сохранена");
      } catch (err) {
        console.log(err);
        this.addToast(this.toastStatuses.error, "Ошибка обновления статуса ПТХ!", "Ошибка обновления статуса ПТХ!");
      }

      this.isLoading = false;
      await this.loadTask(this.$route.params.id);
    },
    invertCheckboxes(checkboxName) {
      if (checkboxName === "notPtc") {
        this.ptc = this.notPtc;
      } else {
        this.notPtc = this.ptc;
      }
      this.stellar = false;
    },
    getNameByType(workType) {
      if (workType === "WARRANTY_WORK") {
        return "Ремонт";
      }
      if (workType === "TECHNICAL_WORK") {
        return "Техническое обслуживание";
      }
      return this.workTypeList.filter((wt) => {
        return wt.value === workType;
      })[0]?.name;
    },
    async loadTemplates() {
      this.isLoading = true;

      try {
        //TODO все такие роуты передалать на константу
        const response = await fetch(
          `${environment.apiUrl}/checklist_templates`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          }
        );
        this.checklistTemplatesList = await response.json();
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
    },
    submitHeader() {
      this.taskHeaderEditingMode = false;
      this.updateHeader();
    },
    editTaskHeader() {
      this.taskHeaderEditingMode = true;
      this.taskHeader = this?.task?.header;
    },
    submitDesc() {
      this.updateDescription();
      if (this.taskDesc.isValid === true) {
        this.taskDescriptionEditingMode = false;
      }
    },
    editTaskDesc() {
      this.taskDescriptionEditingMode = true;
      this.taskDesc.val = this?.task?.description;
    },
    async confirmAndEvaluate(mark) {
      this.isLoading = true;
      try {
        const payload = {
          estimation: mark,
        };
        await fetch(
          `${environment.apiUrl}/v2/tasks/${this.task.id}/done_task`,
          {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(payload),
          }
        );
        await this.loadTask(this.$route.params.id);
      } catch (err) {
        console.log(err);
      }
      this.isLoading = false;
      this.moodConfirmation = false;
    },
    async changeTaskCheckedValue() {
      await this.updateTask(
          () => this.task.checked = this.isTaskChecked,
          () => {
            this.addToast(this.toastStatuses.success, "", "Выполнено!");
          },
          (err) => {
            console.log(err);
            this.addToast(this.toastStatuses.error, "Ошибка при смене статуса проверки!", err);
          });
    },
    async changeTaskCriticalValue() {
      await this.updateTask(
          () => this.task.critical = !this.task.critical,
          () => {
            this.addToast(this.toastStatuses.success, "", "Выполнено!");
          },
          (err) => {
            this.addToast(this.toastStatuses.error, "Ошибка при обновлении заявки!", err);
            console.log(err);
          }
      )
    },
    openConfirmationReturnTask() {
      this.displayReturnTaskConfirmation = true;
    },
    openConfirmation() {
      this.displayConfirmation = true;
    },
    openConfirmationParts() {
      this.displayConfirmationParts = true;
    },
    openConfirmationPtc(name) {
      this.displayConfirmationPtc = true;
      this.invertCheckboxes(name);
    },
    toggleVerification() {
      this.displayVerification = !this.displayVerification;
    },
    verifyStellar() {
      this.task.statusPtc = 'VERIFIED_STELLAR';
      this.submitPtc();
      this.toggleVerification();
    },
    openConfirmationTechnics() {
      this.displayConfirmationTechnics = true;
    },
    async openCompanyChangeConfirmation() {
      this.displayCompanyPopup = true;
      await this.loadNewCompanyTechnics()
      await this.loadNewCompanyEmployees()
      this.companyEmployees = this.companyEmployees.filter((employee) => {
        return !employee.blocked
      }).map((employee) => {
        return {...employee, fullName: `${employee.lastname} ${employee.firstname} - ${employee.phone}`}
      });
    },
    openRepairItemsConfirmation() {
      this.displayConfirmationRepairItems = true;
    },
    openReturnTaskToOperatorConfirmation() {
      this.displayReturnTaskToOperatorConfirmation = true;
    },
    openReviseTaskConfirmation() {
      this.displayReviseTaskConfirmation = true;
    },
    askForceClose() {
      this.displayConfirmationForceCloseTask = true;
    },
    askCancelBuying() {
      this.displayConfirmationCancelBuying = true;
    },
    closeConfirmationReturnTaskToOperator() {
      this.displayReturnTaskToOperatorConfirmation = false;
    },
    closeReviseTaskConfirmation() {
      this.displayReviseTaskConfirmation = false;
    },
    closeConfirmationReturnTask() {
      this.displayReturnTaskConfirmation = false;
    },
    closeForceCloseTask() {
      this.displayConfirmationForceCloseTask = false;
    },
    closeCancelBuyingConfirmation() {
      this.displayConfirmationCancelBuying = false;
    },
    closeConfirmation() {
      this.displayConfirmation = false;
    },
    closeConfirmationParts() {
      this.displayConfirmationParts = false;
    },
    closeConfirmationPtc() {
      this.ptc = ['PTC', 'VERIFIED_STELLAR', 'NOT_VERIFIED_STELLAR'].includes(this.task.statusPtc)
      this.notPtc = this.task.statusPtc === 'NOT_PTC';
      this.stellar = this.task.statusPtc === 'NOT_VERIFIED_STELLAR'
      this.displayConfirmationPtc = false;
    },
    closeConfirmationTech() {
      this.displayConfirmationTechnics = false;
    },
    closeConfirmationRepairItems() {
      this.displayConfirmationRepairItems = false;
    },
    closeReturnTaskToOperatorConfirmationAndPost(event) {
      this.displayReturnTaskToOperatorConfirmation = false;
      this.updateStatus("RETURN OP", event);
    },
    closeConfirmationPartsAndUpdate(event) {
      this.displayConfirmationParts = false;
      this.updateStatus("DETAILS", event);
    },
    closeConfirmationAndSelect() {
      this.closeConfirmation();
      this.assignToEngineer(this.whomToAssignId);
    },
    closeConfirmationAndSelectTech() {
      this.closeConfirmationTech();
      this.assignToTech(this.whomToAssignTechId);
    },
    closeCompanyChangeConfirmation() {
      this.newEmployee = null;
      this.newTechnic = null;
      this.displayCompanyPopup = false;
    },
    toggleCompanyChangeConfirmation() {
      this.displayCompanyConfirmation = !this.displayCompanyConfirmation;
    },
    closeConfirmationAndChangeClient() {
      this.displayCompanyPopup = false;
      this.displayCompanyConfirmation = false;
      this.changeCompany(this.currentClient, this.newEmployee, this.newTechnic);
    },
    closeConfirmationAndSendPtc() {
      this.displayConfirmationPtc = false;
      this.submitPtcStatus();
    },
    closeConfirmationAndSelectRepairItems() {
      this.closeConfirmationRepairItems();
      this.changeRepairType(this.newWorkType);
    },
    toggleMenuByRef(event, refName) {
      this.$refs[refName].toggle(event, event.target);
    },
    getTechnicAssignAlert() {
      if (this.whomToAssignTechId === -1) {
        return `Вы уверены, что хотите сменить технику на Иное?`;
      }
      const t = this.technicList.filter(
        (t) => t.id === this.whomToAssignTechId
      )[0];
      return `Вы уверены, что хотите сменить технику на ${t?.technicTypeInfo?.name}, ${t?.technicProducerInfo?.name}, ${t?.technicModelInfo?.name}, ${t?.series}, ${t?.year}?`;
    },
    getRepairItemsAlert() {
      let alert = `Вы уверены, что хотите сменить вид ремонта на `;
      if (this.workTypeList && this.newWorkType)
        return (
            alert +
            this.workTypeList.filter((wt) => {
              return wt.value === this.newWorkType;
            })[0].name + '?'
        ); else return '-'
    },
    dropdownTechnicItems() {
      let items;
      const otherTechnicTemplate = {
        id: -1,
        label: "Иное",
      };
      items = this.technicList.map((t) => {
        return {
          id: t.id,
          label: `${t.technicTypeInfo.name}, ${t.technicProducerInfo.name}, ${t.technicModelInfo.name}, ${t.series}, ${t.year}`,
        };
      });
      items.unshift(otherTechnicTemplate);
      return items;
    },

    confirmRepairItems(workType) {
      this.newWorkType = workType;
      this.currentChecklistTemplate = null;
      this.openRepairItemsConfirmation();
    },

    async confirmChangeTaskRegion(regionId) {
      if (this.task.regionId === regionId) return;

      this.isLoading = true;

      try {
        this.task.regionId = regionId;
        await tasksClient.updateTask(this.task.id, this.task);
        this.addToast(this.toastStatuses.success, "", "Регион изменён");

        await this.loadTask(this.task.id);
      } catch (err) {
        console.error(err);
      }

      this.isLoading = false;
    },

    confirmAssign(id) {
      if (!id) return;
      this.whomToAssignId = id;
      this.openConfirmation();
    },
    async changeChecklistConfirmation(t) {
      let message =
        "Вы действительно хотите сменить тип чеклиста? Все данные из предыдущего чеклиста будут удалены!";
      this.$confirm.require({
        message: message,
        group: "taskDetailDialog",
        header: "Внимание!",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Да",
        rejectLabel: "Нет",
        accept: () => {
          this.changeChecklistTemplate(t);
        },
      });
    },
    async reviseTaskCheckConfirmation() {
      this.$confirm.require({
        message: "Вы действительно хотите подтвердить доработку?",
        group: "reviseTaskCheckDialog",
        header: "Внимание",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Да",
        rejectLabel: "Нет",
        accept: () => {
          this.reviseTaskCheck();
        },
      });
    },
    async reviseTaskConfirmDialog() {
      this.$confirm.require({
        message: "Вы действительно хотите подтвердить доработку?",
        group: "reviseTaskConfirmDialog",
        header: "Внимание",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Да",
        rejectLabel: "Нет",
        accept: () => {
          this.reviseTaskConfirm();
        },
      });
    },
    async changeChecklistTemplate(template) {
      if (!template.id) return;
      this.isLoading = true;
      let url = `${environment.apiUrl}/tasks/${this.task.id}/work_type`;
      const actionPayload = {
        workType: this?.task?.workType,
        checklistTemplate: template,
      };
      try {
        const response = await fetch(url, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${this.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(actionPayload),
        });

        if (response.status !== 200) {
          const responseData = await response.json();
          throw new Error(
            responseData.message || "Ошибка при смене шаблона чеклста!"
          );
        }
        await this.loadTask(this.$route.params.id);
        this.addToast(this.toastStatuses.success, "", "Тип работ изменён!");

        await this.$refs.checklistComponent.loadCheckList();
      } catch (err) {
        const error = err.message || "Не получилось изменить шаблон чеклиста";
        this.addToast(this.toastStatuses.error, "Ошибка при смене шаблона чек-листа!", error);
      }

      this.isLoading = false;
      this.newWorkType = null;
    },
    async changeRepairType(id) {
      if (!id) return;
      this.isLoading = true;
      let url = `${environment.apiUrl}/tasks/${this.task.id}/work_type`;

      const actionPayload = {
        workType: id,
      };

      const ctlForSending = this.checklistTemplatesList.filter((ctl) => {
        return ctl?.type === this.newWorkType;
      });

      if (ctlForSending.length === 1) {
        actionPayload["checklistTemplate"] = ctlForSending[0];
      }

      try {
        const response = await fetch(url, {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${this.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(actionPayload),
        });

        if (response.status !== 200) {
          const responseData = await response.json();
          throw new Error(
            responseData.message || "Ошибка при смене типа работ!"
          );
        }
        await this.loadTask(this.$route.params.id);

        this.addToast(this.toastStatuses.success, "", "Тип работ изменён!");

        await this.$refs.checklistComponent.loadCheckList();
      } catch (err) {
        const error = err.message || "Не получилось изменить тип работ";
        this.addToast(this.toastStatuses.error, "Ошибка при смене типа работ!", error);
      }

      this.isLoading = false;
      this.newWorkType = null;
    },
    confirmAssignTechnics(id) {
      if (!id) return;
      this.whomToAssignTechId = id;
      this.openConfirmationTechnics();
    },
    confirmChangeCompany(client) {
      if (!client) return;
      this.currentClient = client;
      this.openCompanyChangeConfirmation();
    },
    async assignToEngineer(id) {
      if (!id) return;
      this.isLoading = true;
      let url = `${environment.apiUrl}/tasks/${this.task.id}/assign_engineer`;

      const actionPayload = this.$store.getters["myprofiles/engineers"].filter(
        (e) => e.id === id
      )[0];

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(actionPayload),
        });

        if (response.status !== 200) {
          const responseData = await response.json();
          throw new Error(
            responseData.message || "Ошибка при назначении инженера!"
          );
        }
        this.changeEditEngineerMode();
        await this.loadTask(this.$route.params.id);
        this.addToast(this.toastStatuses.success, "", "Инженер назначен!");
      } catch (err) {
        const error = err.message || "Не получилось назначить инженера";
        this.addToast(this.toastStatuses.error, "Ошибка при назначении инженера!", error);
      }
      this.whomToAssignId = null;
      this.whomToAssignTechId = null;
      this.isLoading = false;
    },
    async changeOtherTechnicFlag(value) {
      try {
        this.task.otherTechnic = value;
        await fetch(`${environment.apiUrl}/tasks/${this.task?.id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
            "Content-Type": "application/json",
          },
          method: "PUT",
          body: JSON.stringify(this.task),
        });
      } catch (err) {
        this.addToast(this.toastStatuses.error, "Ошибка при изменении техники!", err);
      }
    },
    async changeCompany(company, employee, technic) {
      await this.updateTask(
          () =>  {
            this.task.company = company
            this.task.employee = employee
            this.task.technic = technic
          },
          () => this.addToast(this.toastStatuses.success, "Компания успешно обновлена", ""),
          (err) => this.addToast(this.toastStatuses.error, "Ошибка при обновлении компании!", err)
      )
    },
    async assignToTech(id) {
      if (!id) return;
      this.isLoading = true;
      if (id === -1) {
        await this.changeOtherTechnicFlag(true);
        this.addToast(this.toastStatuses.success, "", "Техника изменена!");
      } else {
        await this.changeOtherTechnicFlag(false);
        let url = `${environment.apiUrl}/tasks/${this.task.id}/technic`;

        const actionPayload = this.technicList.filter((t) => t.id === id)[0];
        this.task.otherTechnic = false;

        try {
          const response = await fetch(url, {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(actionPayload),
          });

          if (response.status !== 200) {
            const responseData = await response.json();
            throw new Error(
              responseData.message || "Ошибка при смене техники!"
            );
          }
          this.addToast(this.toastStatuses.success, "", "Техника изменена!");
        } catch (err) {
          const error = err.message || "Не получилось изменить технику";
          this.addToast(this.toastStatuses.error, "Ошибка при изменении техники!", error);
        }
      }
      await this.loadTask(this.$route.params.id);
      this.whomToAssignId = null;
      this.whomToAssignTechId = null;
      this.isLoading = false;
    },
    async loadTask(id) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("myprofiles/loadEmployes");
        const response = await fetch(`${environment.apiUrl}/tasks/${id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
            "Content-Type": "application/json",
          },
        });
        if (response.status === 400) {
          this.$router.replace(`/404`);
          return;
        }
        const responseData = await response.json();

        this.isCritical = responseData.critical;
        this.isTaskChecked = responseData.checked;
        this.id = responseData.id;
        this.ptc = this.ptcStatuses.includes(responseData.statusPtc);
        this.stellar = responseData.statusPtc === 'NOT_VERIFIED_STELLAR';
        this.notPtc = responseData.statusPtc === "NOT_PTC";
        this.typePtc = responseData?.typePtc;
        this.numberPtc = responseData?.numberPtc;
        this.textPtc = responseData?.textPtc;
        this.editEngineerMode = false;
        this.currentChecklistTemplate = responseData?.checklistInfo;
        this.unreadMessagesCount = responseData.unreadMessagesCount;

        const t = responseData;
        const d = new Date(responseData.created);
        this.taskStatus = ROLES_LIST_TASK_STATUSES.find(
          (r) => r.value === t.status
        );

        if (responseData.ended && this.role === "OPERATOR") {
          this.date = new Date(responseData.ended);
        }

        this.task = {
          ...responseData,
          statusText: getTaskStatusText(t.status),
          createdText: this.getFormattedDate(d),
          techLabel: `${t.technic.technicTypeInfo.name}, ${t.technic.technicProducerInfo.name}, ${t.technic.technicModelInfo.name}, ${t.technic.series}, ${t.technic.year}`,
        };

        await this.loadOtherCompanyEmployees();
      } catch (err) {
        const error = err.message || "Не получилось загрузить заявку";
        this.addToast(this.toastStatuses.error, "Ошибка при загрузке заявки!", error);
      }

      this.technicList = await this.$store.dispatch(
        "myprofiles/loadTechnicForClient",
        this.task.company.uid
      );

      this.clientList = this.$store.getters["myprofiles/clients"].map((comp) => {
        return {
          ...comp,
          fullName: `${comp.name} - ${comp.inn}`
        }
      });

      this.isLoading = false;
    },

    async loadOtherCompanyEmployees() {
      this.task = {
        ...this.task,
        otherEmployees: (
          await this.$store.dispatch(
            "myprofiles/loadEmployeeForClient",
            this.task?.company.uid
          )
        ).filter((employee) => {
          return (
            employee.id !== this.task?.employee?.id && employee.block === false
          );
        }),
      };
    },
    async loadNewCompanyTechnics() {
      this.companyTechnics = await this.$store.dispatch(
          "myprofiles/loadTechnicForClient",
          this.currentClient.uid
      );
    },
    async loadNewCompanyEmployees() {
      this.companyEmployees = await this.$store.dispatch(
          "myprofiles/loadEmployeeForClient",
          this.currentClient.uid
      )
    },

    async updateStatus(newStatus, text = "") {
      this.isLoading = true;
      let url = `${environment.apiUrl}/tasks/${this.task.id}/`;
      switch (newStatus) {
        case "DONE":
          url += "done_task";
          break;
        case "DONE_NO_SO":
          url += "done_no_so_task";
          break;
        case "TAKE IN WORK":
          url += "take_to_work";
          break;
        case "RETURN OP":
          url = `${environment.apiUrl}/v2/tasks/${this.task.id}/return_task`;
          break;
        case "CLOSE":
          url += "close_task";
          break;
        case "FORCE_CLOSE":
          url += "cancel";
          break;
        case "DETAILS":
          url = `${environment.apiUrl}/v2/tasks/${this.task.id}/need_details`;
          break;
        case "DETAILS_BOUGHT":
          url += "details_purchased";
          break;
        case "DETAILS_NOT_NECESSARY":
          url += "purchase_details_not_necessary";
          break;
      }

      try {
        const initObject = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.token}`,
            "Content-Type": "application/json",
          },
        };

        if (this.statusesWithReasons.includes(newStatus)) {
          initObject.body = JSON.stringify({ text: text });
        }

        const response = await fetch(url, initObject);

        let responseData = null;

        if (response.status !== 200) {
          responseData = await response.json();
          throw new Error(responseData.message || "Ошибка обновления статуса!");
        }

        await this.loadTask(this.$route.params.id);
        if (newStatus === "TAKE IN WORK") {
          await this.$refs.commandWorkComponent.loadTaskEngineers();
        }
        this.addToast(this.toastStatuses.success, "", "Статус обновлён!")
      } catch (err) {
        const error = err.message || "Не получилось обновить статус";
        this.addToast(this.toastStatuses.error, "Ошибка при обновлении статуса!", error)
      }

      this.isLoading = false;
    },
    async closeForceCloseTaskAndCloseTask(event) {
      this.displayConfirmationForceCloseTask = false;
      await this.updateStatus("FORCE_CLOSE", event);
    },
    async closeConfirmationAndCancelBuying(event) {
      this.displayConfirmationCancelBuying = false;
      await this.updateStatus("DETAILS_NOT_NECESSARY", event);
    },
    loadedChecklist(data) {
      this.isChecklistChecked = true;
      this.currentChecklistTemplate = data;
      for (const i of data?.items) {
        if (!i.updated) {
          this.isChecklistChecked = false;
          break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// 935
.employee {
  &.main {
    font-weight: 700;
  }
  &.blocked {
    opacity: 0.5;
  }
}
.pencil-button {
  width: 1rem;
  height: 1rem;
  padding: 12px;
}
.p-button-label {
  font-size: 12px;
}
.data {
  font-size: 16px;
  font-weight: bold;
}
span.pi-pencil {
  color: green;
  cursor: pointer;
}
.p-menu-overlay {
  width: 500px;
}
.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.critical-label {
  color: red;
}

.moods {
  width: 200px;
  cursor: pointer;
}
.moods:hover {
  fill: #358539;
}
h4 {
  font-weight: 700;
}
h5 {
  font-weight: 700;
}

.card {
  margin-bottom: 0;
}
.card.card-w-title {
  padding-bottom: 0.4rem;
}
.li.p-highlight {
  width: 80px;
}
.badge-numbers {
  position: absolute;
  right: 5px;
  min-height: 21px;
  min-width: 21px;
}
</style>
