<template>
  <div class="p-d-flex p-mb-3">
    <FileUpload
      mode="basic"
      name="demo[]"
      :url="url"
      chooseLabel="Выберите файл"
      :auto="true"
      :customUpload="true"
      ref="uploadBtn"
      @uploader="myUploader"
    >
      <template #empty>
        <p>Загрузите файл</p>
      </template>
    </FileUpload>
    <!--    TODO Create map for TASK_STATUSES-->
    <Button
      v-if="
        task &&
        task.status !== 'NEW' &&
        task.status !== 'WAIT_ENGINEER_CONFIRM' &&
        task.status !== 'WORK_ENGINEER' &&
        task.status !== 'PURCHASING_PARTS'
      "
      @click="createTaskReport"
      class="create-report-button"
      :disabled="isLoading"
    >
      Создать отчет по заявке
    </Button>
  </div>
  <div class="p-d-flex p-jc-between p-ai-center p-flex-row p-mb-3">
    <div style="font-size: 20px">Файлы</div>
    <div v-if="permissions['download_zip']">
      <Button @click="downloadZip">Скачать все файлы</Button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-8" v-for="file of files" :key="file?.id">
      <file-icon
        @loadFiles="loadFiles"
        :file="file"
        :has-delete-button="false"
      />
    </div>
    <div v-if="files?.length === 0">Файлов пока нет</div>
  </div>
</template>

<script>
import { environment } from "@/config";
import FileIcon from "@/views/tasks/FileIcon";
import Compressor from "compressorjs";
import tasksClient from "@/api/tasksClient";

export default {
  name: "TaskFiles",
  components: { FileIcon },
  props: ["task", "filesFromParent"],
  created() {
    this.files = this.filesFromParent;
    this.displayFiles();
  },
  computed: {
    permissions() {
      return this.$store.getters.getPermissions;
    },
    url() {
      return `${environment.apiUrl}/tasks/${this.task.id}/files`;
    },
    apiUrl() {
      return `${environment.apiUrl}`;
    },
  },
  data() {
    return {
      files: [],
      filesObjects: [],
      isLoading: false,
      imageTypes: ["image/png", "image/jpeg", "image/jpg"],
    };
  },
  methods: {
    async createTaskReport() {
      this.isLoading = true;

      try {
        await tasksClient.createTaskReport(this.task.id);
        await this.loadFiles();
      } catch (err) {
        console.error(err);
      }

      this.isLoading = false;
    },
    async downloadZip() {
      this.isLoading = true;

      try {
        const response = await fetch(
          `${environment.apiUrl}/tasks/${this.task.id}/zip`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        );
        const url = window.URL.createObjectURL(await response.blob());
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Заявка №${this.task.number}.zip`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
    },
    clearValidity(input) {
      this[input].isValid = true;
    },
    compressImage(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          maxWidth: 1920,
          maxHeight: 1920,
          success: resolve,
          error: reject,
        });
      });
    },
    async myUploader(event) {
      let file = event.files[0];
      if (this.imageTypes.includes(file.type)) {
        file = await this.compressImage(file);
        if (file instanceof Blob) {
          file = new File([file], file.name);
        }
      }
      console.log(file);
      const data = new FormData();
      data.append("file", file);

      await fetch(this.url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.$store.getters.token}`,
          // "Content-Type": "application/json",
        },
        body: data,
      });

      await this.loadFiles();
      this.$refs.uploadBtn.clear();
    },
    validateForm() {
      this.formIsValid = true;

      this.message.isValid = true;

      if (!this.message.val) {
        this.message.isValid = false;
        this.formIsValid = false;
      }
    },
    async displayFiles() {
      if (!this.files) return;
      this.files = this.files.map((f) => {
        return {
          ...f,
          href: this.apiUrl + "/files/" + f.path.replace(/\//g, "%2F"),
        };
      });

      this.filesObjects = [];

      const promiseGet = this.files.map((f, i) => {
        return fetch(f.href, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
          },
        })
          .then((response) => {
            return response.blob();
          })
          .then((response) => {
            this.filesObjects[i] = response;
          });
      });

      await Promise.all(promiseGet);

      for (let i = 0; i < this.files.length; i++) {
        this.files[i].obj = URL.createObjectURL(this.filesObjects[i]);
      }

      this.$store.dispatch("data/setTaskFiles", this.files);
    },
    async loadFiles() {
      if (!this.task?.id) return;
      this.isLoading = true;
      try {
        const response = await fetch(
          `${environment.apiUrl}/tasks/${this.task.id}/files`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.files = await response.json();

        await this.displayFiles();
      } catch (err) {
        const error = err.message || "Не получилось загрузить список файлов";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при загрузке списка файлов!",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.create-report-button {
  width: auto;
  margin-left: 20px;
}
::v-deep(.p-scrollpanel) {
  p {
    padding: 0.5rem;
    line-height: 1.5;
    margin: 0;
  }

  &.custombar1 {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--surface-b);
    }

    .p-scrollpanel-bar {
      background-color: var(--primary-color);
      opacity: 1;
      transition: background-color 0.2s;

      &:hover {
        background-color: #007ad9;
      }
    }
  }
}
</style>
