<template>
  <Badge
    style="width: fit-content"
    :class="contractStatusColorClass(contract.status)"
  >
    {{ contractText }}
  </Badge>
</template>

<script>
import {
  CONTRACT_STATUS,
  contractStatusColorClass,
} from "@/models/contractStatus";

export default {
  name: "TaskContract",
  methods: { contractStatusColorClass },
  props: ["contract"],
  computed: {
    contractText() {
      let baseText = `По договору ${this.contract.name} -`;
      switch (this.contract.status) {
        case CONTRACT_STATUS.NOT:
          return `${baseText} отсутствует договор`;
        case CONTRACT_STATUS.COPY:
          return `${baseText} копия`;
        case CONTRACT_STATUS.ORIGINAL:
          return `${baseText} оригинал`;

        default:
          return undefined;
      }
    },
  },
};
</script>
