<template>
  <div class="p-grid">
    <div class="p-col-12" v-if="checklist?.items?.length !== 0">
      <h5 class="p-text-bold">{{ checklist?.name }}</h5>
      <div v-for="(item, index) of checklist?.items" :key="item?.id">
        <div v-if="item.type === 'TEXT'" class="p-d-flex">
          <span class="index">{{ incrementIndexInCycle(index + 1) }})</span>
          <checklist-text
            :item="item"
            :task="task"
            :key="item?.id"
            @updatedText="loadCheckList"
          />
        </div>
        <div v-if="item.type === 'FILES'" class="p-d-flex">
          <span class="index">{{ incrementIndexInCycle(index + 1) }})</span>
          <checklist-files
            :item="item"
            :task="task"
            :key="item?.id"
            :id="index"
            @updatedText="loadCheckList"
          />
        </div>
        <div v-if="item.type === 'SWITCH'" class="p-d-flex p-ai-center p-mb-2">
          <span class="index">{{ incrementIndexInCycle(index + 1) }})</span>
          <checklist-checkbox
            :item="item"
            :task="task"
            :key="item?.id"
            @updatedText="loadCheckList"
          />
        </div>
        <div v-if="item.type === 'FILE'" class="p-d-flex">
          <span class="index">{{ incrementIndexInCycle(index + 1) }})</span>
          <checklist-file
            :item="item"
            :task="task"
            :key="item?.id"
            @updatedText="loadCheckList"
          />
        </div>
      </div>
    </div>
    <div v-else class="p-d-flex">
      <div>
        У вас не выбран чеклист. <br />Выбрать чеклист можно во вкладке
        Информация
      </div>
    </div>
  </div>
</template>

<script>
import { environment } from "@/config";
import ChecklistText from "@/views/tasks/checklist-items/ChecklistText";
import ChecklistCheckbox from "@/views/tasks/checklist-items/ChecklistCheckbox";
import ChecklistFile from "@/views/tasks/checklist-items/ChecklistFile";
import ChecklistFiles from "@/views/tasks/checklist-items/ChecklistFiles";

export default {
  name: "TaskCheckList",
  components: {
    ChecklistFiles,
    ChecklistFile,
    ChecklistCheckbox,
    ChecklistText,
  },
  props: ["task", "checklistFromParent"],
  emits: ["loaded-checklist"],
  created() {
    this.checklist = this.checklistFromParent;
  },
  computed: {
    permissions() {
      return this.$store.getters.getPermissions;
    },
    myId() {
      return this.$store.getters.getId;
    },
  },
  data() {
    return {
      checklist: {},
      isLoading: false,
      checklistIndex: 1,
    };
  },
  methods: {
    incrementIndexInCycle(index) {
      this.checklistIndex = index;
      return index;
    },
    clearValidity(input) {
      this[input].isValid = true;
    },
    async loadCheckList() {
      if (!this.task?.id) return;
      this.isLoading = true;
      try {
        const response = await fetch(
          `${environment.apiUrl}/tasks/${this.task.id}/checklist`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
          }
        );
        this.checklist = await response.json();
        this.$emit("loaded-checklist", this.checklist);
      } catch (err) {
        console.log(err);
        const error = err.message || "Не получилось загрузить чеклист";
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при загрузке чеклиста!",
          detail: error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep(.p-scrollpanel) {
  p {
    padding: 0.5rem;
    line-height: 1.5;
    margin: 0;
  }

  &.custombar1 {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--surface-b);
    }

    .p-scrollpanel-bar {
      background-color: var(--primary-color);
      opacity: 1;
      transition: background-color 0.2s;

      &:hover {
        background-color: #007ad9;
      }
    }
  }
}

.index {
  padding-right: 3px;
  margin-right: 7px;
}
</style>
